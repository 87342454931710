import { AfterViewInit, Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { Subscription } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-product-recommendation',
  templateUrl: './product-recommendation.component.html',
  styleUrls: ['./product-recommendation.component.css']
})
export class ProductRecommendationComponent {

  selectedProduct: any;
  isSkipped: boolean = false;
  isEditing: boolean = false;
  pageName: String = "Product Recommendation";
  activeTab: string = 'start';
  selectedProductType: string = "";
  showProductRecommendation: boolean = false;
  lifeCustomerDetails: any;
  healthCustomerDetails: any;
  display: boolean = false;
  reason1: boolean = false;
  reason2: boolean = false;
  reason3: boolean = false;
  reason4: boolean = false;
  others: boolean = false;
  selectedReasonsArray: string[] = [];
  feedback: string = '';
  pointersDisliked: boolean = false;
  pointersLiked: boolean = false;
  pointsResponse: any;
  points = [];
  otherPoints = []
  paginatedPoints = [];
  currentPage = 0;
  pointsPerPage = 6;
  totalPages = [];
  pitchLoading: boolean = true;
  age: any = 0;
  profileFilled = false;
  inputValue = '';
  compareProductsOptions: any = [];
  selectedProductToCompare: string = "";
  selectedCompanyToCompare: string = "";
  isComparePopupOpen: boolean = false;
  isComparisionPointsLoading: boolean = false;
  comparisionPoints: any = [];
  generatedPayloadForSalesPitch: any;
  productRecommendations: any[] = [];
  selectedRecommendationPoints: any = [];
  isKnowMoreDialogOpen: boolean = false;
  selectedProductFromRecommendation: any;
  isCustomerProfileInsufficient: boolean = false;
  errorMessage: String;
  selectedRecommendationCompany: any;
  history: any;
  lastRoute: any;
  $historySubscription: Subscription;
  $recommendationProductsSubscription: Subscription;
  $profileSubscription: Subscription;
  isRecommendationAlreadyGenerated: boolean = false;
  isOnLoad: boolean = true;


  constructor(private commonService: CommonService, private apiService: ApiService
    , private router: Router
  ) {
    commonService.productSelection.subscribe((res) => {
      this.selectedProduct = res;
      this.selectedProductType = this.selectedProduct.productType;
    })
    this.$historySubscription = commonService.history.subscribe((res) => {
      this.history = res;
      this.lastRoute = this.history[this.history.length - 1];
    })
    this.$profileSubscription = commonService.customerProfile.subscribe((profile) => {
      if ((profile.string != null || profile.form != null)&& this.isOnLoad) {
        this.isOnLoad = false;
        if (profile.string != null) {
          this.inputValue = profile.string;
          this.send();
          this.isSkipped = true;
          this.showProductRecommendation = false;
        }
        else {
          this.showProductRecommendation = true;
          this.isSkipped = false;
          if (this.selectedProductType === 'life')
            this.customerDetailsForLife(profile.form);
          else
            this.customerDetailsForHealth(profile.form)
        }
      }
    })
    this.$recommendationProductsSubscription = commonService.productRecommendation.subscribe((res) => {
      if (res != null && res.length > 0 && this.isOnLoad) {
        this.productRecommendations = res.recommendation;
        this.isRecommendationAlreadyGenerated = true;
      }
      else
      this.isRecommendationAlreadyGenerated = false;
    })
  }


  setActiveTab(tab: string): void {
    this.activeTab = tab;
  }

  customerDetailsForHealth(event: any) {
    if (!event.isSkipped) {
      this.resetSessionId();
      this.isSkipped = false;
      this.profileFilled = true;
      this.showProductRecommendation = true;
      this.healthCustomerDetails = event;
      localStorage.setItem("customerProfileForm", this.healthCustomerDetails);
      let self = this.healthCustomerDetails.members.filter(m => m.relationship === 'self')
      if (self.length > 0) {
        this.age = self[0].age;
      }
      let dependants = "";
      if (this.healthCustomerDetails.members.length > 0) {
        dependants = this.getCommaSeperatedString(this.getSelectedMembers(this.healthCustomerDetails.members));
      }
      let diseases = "";
      if (this.healthCustomerDetails.healthConditions.length > 0) {
        const diseases = this.getCommaSeperatedString(this.getStringValues(this.healthCustomerDetails.healthConditions));
      }
      const existingInsurance = this.getInsuranceString();
      let payLoad = {
        gender: this.healthCustomerDetails.gender,
        dependants: dependants,
        diseases: diseases,
        existing_insurance: existingInsurance,
        location: this.selectedProduct.location,
        session_id: sessionStorage.getItem('sessionId'),
        ...(this.age != 0 && { age: this.age })
      }
      this.generatedPayloadForSalesPitch = payLoad;
      if (this.productRecommendations.length===0) {
        let custProfile = {
          string: null,
          form: this.healthCustomerDetails,
          from: 'recommendation'
        }
        this.commonService.setCustomerProfile(custProfile);
      let customerProfile = this.getCustomerSummaryForHealth(this.age, this.healthCustomerDetails);
      this.apiService.getProductRecommendation(this.selectedProduct.productType, customerProfile).subscribe((res) => {
        const hasRecommendation = 'recommendation' in res;
        if (hasRecommendation){
          this.commonService.setProductRecommendations(res.recommendation);
          this.productRecommendations = res.recommendation;
        }
        else
          {
            this.isCustomerProfileInsufficient = true;
            this.commonService.setProductRecommendations([]);
            this.errorMessage = res.error
          }
        this.pitchLoading = false;
        this.productRecommendations.forEach(element => {
          setTimeout(() => {
            this.apiService.getCompanyLogo(element.company).subscribe((blob) => {
              const objectURL = URL.createObjectURL(blob);
              element.companyLogo = objectURL
            });
          })
        });
      })
    }
    }
    else {
      this.isSkipped = true;
      this.startEditing();
    }
  }

  customerDetailsForLife(event: any) {
    if (!event.isSkipped) {
      localStorage.setItem("customerProfileForm", event);
      this.resetSessionId();
      this.isSkipped = false;
      this.profileFilled = true;
      this.showProductRecommendation = true;
      this.lifeCustomerDetails = event;
      let familyMembers = "";
      if (this.lifeCustomerDetails?.familyMembers.length > 0) {
        familyMembers = this.getCommaSeperatedString(this.getStringValues(this.lifeCustomerDetails?.familyMembers));
      }
      let goals = "";
      if (this.lifeCustomerDetails?.lifeGoals.length > 0) {
        goals = this.getCommaSeperatedString(this.getStringValues(this.lifeCustomerDetails?.lifeGoals))
      }
      let payload = {
        age: this.lifeCustomerDetails.age,
        gender: this.lifeCustomerDetails.gender,
        occupation: this.lifeCustomerDetails.occupation,
        family_members: familyMembers,
        expected_retirement_age: this.lifeCustomerDetails.retirementAge,
        annual_income: this.lifeCustomerDetails.annualIncome,
        annual_expenditure: this.lifeCustomerDetails.annualExpenditure,
        investments: this.lifeCustomerDetails.investments,
        outstanding_loans: this.lifeCustomerDetails.outstandingLoans,
        life_goals: goals,
        amount_needed: this.lifeCustomerDetails.amountNeeded,
        existing_insurance: this.lifeCustomerDetails.healthInsurance,
        coverage: this.lifeCustomerDetails.lifeInsuranceCoverage,
        location: this.lifeCustomerDetails.location,
        session_id: sessionStorage.getItem('sessionId')
      }
      this.generatedPayloadForSalesPitch = payload;
      let customerProfile = this.getCustomerSummaryForLife(this.lifeCustomerDetails);
      if (this.productRecommendations.length===0) {
        let custProfile = {
          string: null,
          form: event,
          from: 'recommendation'
        }
        this.commonService.setCustomerProfile(custProfile);
      this.apiService.getProductRecommendation(this.selectedProduct.productType, customerProfile).subscribe((res) => {
        const hasRecommendation = 'recommendation' in res;
        if (hasRecommendation){
          this.commonService.setProductRecommendations(res.recommendation);
          this.productRecommendations = res.recommendation;
        }
        else
          {
            this.isCustomerProfileInsufficient = true;
            this.errorMessage = res.error
            this.commonService.setProductRecommendations([]);
          }
        this.pitchLoading = false;
        this.productRecommendations.forEach(element => {
          setTimeout(() => {
            this.apiService.getCompanyLogo(element.company).subscribe((blob) => {
              const objectURL = URL.createObjectURL(blob);
              element.companyLogo = objectURL
            });
          })
        });
      })
    }
    }
    else {
      this.isSkipped = true;
      this.startEditing();
    }
  }

  onSubmit() {
  }

  redirectToProductQueries() {

  }

  showDialog() {
    if (!this.pointersLiked && !this.pointersDisliked) {
      this.display = true;
    }
  }

  getStringValues(items) {
    return items.map(item => item.item_text);
  }

  getCommaSeperatedString(values): string {
    if (values.length === 0) {
      return '';
    }
    if (values.length === 1) {
      return values[0];
    }
    if (values.length === 2) {
      return values.join(' and ');
    }
    const lastGoal = values.pop();
    return values.join(', ') + ', and ' + lastGoal;
  }

  getSelectedMembers(object: any) {
    return object.map(member => member.relationship);
  }

  getInsuranceString(): string {
    if (this.healthCustomerDetails.insuranceNone) {
      return 'no';
    }
    if (this.healthCustomerDetails.insuranceGroup && this.healthCustomerDetails.insuranceRetail) {
      return 'Group and Individual';
    }
    if (this.healthCustomerDetails.insuranceGroup) {
      return 'group';
    }
    if (this.healthCustomerDetails.insuranceRetail) {
      return 'individual';
    }
    return 'Insurance status unknown';
  }

  isDropdownOpen = false;

  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  redirectToCustomerDetails() {
    this.showProductRecommendation = false;
    this.profileFilled = false;
    this.isSkipped = false;
    this.paginatedPoints = [];
    this.inputValue = '';
  }

  paginatePoints() {
    this.paginatedPoints = [];
    for (let i = 0; i < this.points.length; i += this.pointsPerPage) {
      this.paginatedPoints.push(this.points.slice(i, i + this.pointsPerPage));
    }
  }

  paginateOtherPoints() {
    this.paginatedPoints = [];
    for (let i = 0; i < this.otherPoints.length; i += this.pointsPerPage) {
      this.paginatedPoints.push(this.otherPoints.slice(i, i + this.pointsPerPage));
    }
  }

  calculateTotalPages() {
    this.totalPages = Array(Math.ceil(this.points.length / this.pointsPerPage)).fill(0).map((x, i) => i);
    this.currentPage = 0;
  }

  calculateTotalPagesForOtherPoints() {
    this.totalPages = Array(Math.ceil(this.otherPoints.length / this.pointsPerPage)).fill(0).map((x, i) => i);
    this.currentPage = 0;
  }

  nextPage() {
    if (this.currentPage < this.paginatedPoints.length - 1) {
      this.currentPage++;
    }
  }

  prevPage() {
    if (this.currentPage > 0) {
      this.currentPage--;
    }
  }

  goToPage(page: number) {
    this.currentPage = page;
  }

  onOthersClicked() {
    this.paginateOtherPoints();
    this.calculateTotalPagesForOtherPoints();
  }

  onStartClicked() {
    this.paginatePoints();
    this.calculateTotalPages();
  }

  startEditing() {
    this.isEditing = true;
    setTimeout(() => {
      const inputElement = document.querySelector('textarea') as HTMLTextAreaElement;
      if (inputElement) {
        inputElement.focus();
      }
    }, 0);
  }

  send() {
    localStorage.setItem("customerProfileDetails", this.inputValue);
    this.resetSessionId();
    this.profileFilled = true
    this.isEditing = false;
    this.isCustomerProfileInsufficient = false;
    this.pitchLoading = true;
    this.paginatedPoints = [];
    this.selectedProductToCompare = "";
    this.selectedCompanyToCompare = "";
    this.pointersLiked = false;
    this.pointersDisliked = false;
    this.isSkipped = true;
    const customer_profile = this.inputValue;
    if (this.productRecommendations.length===0 || !this.isOnLoad) {
      let custProfile = {
        string: this.inputValue,
        form: null,
        from: 'recommendation'
      }
      this.commonService.setCustomerProfile(custProfile);
    this.apiService.getProductRecommendation(this.selectedProduct.productType, customer_profile).subscribe((res) => {
      const hasRecommendation = 'recommendation' in res;
        if (hasRecommendation)
          this.productRecommendations = res.recommendation;
        else
          {
            this.isCustomerProfileInsufficient = true;
            this.errorMessage = res.error
          }
      this.pitchLoading = false;
      this.productRecommendations.forEach(element => {
        setTimeout(() => {
          this.apiService.getCompanyLogo(element.company).subscribe((blob) => {
            const objectURL = URL.createObjectURL(blob);
            element.companyLogo = objectURL
          });
        })
      });
    })
  }
  }

  adjustTextareaHeight(event: Event): void {
    const textarea = event.target as HTMLTextAreaElement;
    textarea.style.height = 'auto';  // Reset the height to auto
    textarea.style.height = textarea.scrollHeight + 'px';  // Set it to the scroll height

    // Ensure the outer container grows with the textarea
    const container = textarea.closest('.profile-container') as HTMLElement;
    if (container) {
      container.style.height = 'auto';  // Reset the container height to auto
      container.style.height = container.scrollHeight + 30 + 'px';  // Set it to the scroll height
    }
  }


  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const target = event.target as HTMLElement;
    const clickedInside = target.closest('.navigation-options') || target.closest('.toggle-button');

    if (!clickedInside && this.isDropdownOpen || this.isCompareDropdownOpen) {
      this.isDropdownOpen = false;
      this.isCompareDropdownOpen = false;
      this.changeButtonVisibility(false);
    }
  }

  isCompareDropdownOpen = false;

  toggleCompareDropdown(event: Event) {
    event.stopPropagation();
    if (this.selectedProductFromRecommendation) {
      if (this.isCompareDropdownOpen)
        this.changeButtonVisibility(false);
      else
        this.changeButtonVisibility(true);
      this.isCompareDropdownOpen = !this.isCompareDropdownOpen;
    }
  }

  productClicked(product: any) {
    this.selectedProductToCompare = product.product;
    this.selectedCompanyToCompare = product.company;
    this.isCompareDropdownOpen = false;
    this.changeButtonVisibility(false);
  }

  openComparePopup() {
    this.isComparePopupOpen = true;
    this.comparisionPoints = [];
    const customer_profile = this.inputValue;
    this.isComparisionPointsLoading = true;
    if (this.isSkipped) {
      this.apiService.getComparisonSummaryWithProfile(customer_profile, this.selectedProduct.company, this.selectedProduct.product
        , this.selectedProduct.productType, this.selectedProductToCompare, this.selectedCompanyToCompare).subscribe({
          next: (res) => {
            const parser = new DOMParser();
            let doc = parser.parseFromString(res.comparison, 'text/html');
            this.comparisionPoints = Array.from(doc.querySelectorAll('p + ol:nth-of-type(1) li')).map(li => li.outerHTML);
            this.isComparisionPointsLoading = false;
          },
          error: (err) => {

          }
        })
    }
    else {
      this.generatedPayloadForSalesPitch = {
        ...this.generatedPayloadForSalesPitch,
        product2: this.selectedProductToCompare,
        company2: this.selectedCompanyToCompare
      };
      this.apiService.getComparisonSummaryWithFormDetails(this.generatedPayloadForSalesPitch, this.selectedProduct.company, this.selectedProduct.product
        , this.selectedProduct.selectedProductType, this.selectedProductToCompare, this.selectedCompanyToCompare
      ).subscribe({
        next: (res) => {
          const parser = new DOMParser();
          let doc = parser.parseFromString(res.comparison, 'text/html');
          this.comparisionPoints = Array.from(doc.querySelectorAll('p + ol:nth-of-type(1) li')).map(li => li.outerHTML);
          this.isComparisionPointsLoading = false;
        },
        error: (res) => {

        }
      })
    }
  }

  changeButtonVisibility(flag: boolean) {
    let element = document.getElementById("compare-button") as HTMLElement;
    if (flag)
      element.style.zIndex = '10';
    else
      element.style.zIndex = '0';
  }

  toggleEditingMode() {
    this.showProductRecommendation = false;
    this.isSkipped = true;
    this.profileFilled = false;
    this.paginatedPoints = [];
    this.otherPoints = [];
    this.selectedProductToCompare = "";
    this.selectedCompanyToCompare = "";
  }

  resetSessionId() {
    sessionStorage.removeItem('sessionId'); // Remove the current session ID
    const newSessionId = this.generateRandomString(16); // Generate a new random session ID
    sessionStorage.setItem('sessionId', newSessionId); // Set the new session ID
  }

  generateRandomString(length) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  getSelectedReasons() {
    // Handle 'Not relevant'
    if (this.reason1 && !this.selectedReasonsArray.includes('Not relevant')) {
      this.selectedReasonsArray.push('Not relevant');
    } else if (!this.reason1) {
      this.selectedReasonsArray = this.selectedReasonsArray.filter(reason => reason !== 'Not relevant');
    }

    // Handle 'Not accurate'
    if (this.reason2 && !this.selectedReasonsArray.includes('Not accurate')) {
      this.selectedReasonsArray.push('Not accurate');
    } else if (!this.reason2) {
      this.selectedReasonsArray = this.selectedReasonsArray.filter(reason => reason !== 'Not accurate');
    }

    // Handle 'Repetitive'
    if (this.reason3 && !this.selectedReasonsArray.includes('Repetitive')) {
      this.selectedReasonsArray.push('Repetitive');
    } else if (!this.reason3) {
      this.selectedReasonsArray = this.selectedReasonsArray.filter(reason => reason !== 'Repetitive');
    }

    // Handle "Can't understand"
    if (this.reason4 && !this.selectedReasonsArray.includes("Can't understand")) {
      this.selectedReasonsArray.push("Can't understand");
    } else if (!this.reason4) {
      this.selectedReasonsArray = this.selectedReasonsArray.filter(reason => reason !== "Can't understand");
    }

    // Handle 'Others'
    if (this.others && !this.selectedReasonsArray.includes('Others')) {
      this.selectedReasonsArray.push('Others');
    } else if (!this.others) {
      this.selectedReasonsArray = this.selectedReasonsArray.filter(reason => reason !== 'Others');
    }

    // Join the selected reasons into a single string
    const selectedReasons = this.selectedReasonsArray.join(', ');

    return selectedReasons;
  }

  getCustomerSummaryForLife(lifeCustomerDetails: any): string {

    // Build the summary string
    const age = lifeCustomerDetails.age ? lifeCustomerDetails.age : '?';
    const gender = lifeCustomerDetails.gender ? lifeCustomerDetails.gender : '?';
    const location = lifeCustomerDetails.location ? lifeCustomerDetails.location : '?';
    const familyMembers = lifeCustomerDetails.familyMembers.length > 0
      ? this.getCommaSeperatedString(this.getStringValues(lifeCustomerDetails.familyMembers))
      : '?';
    const lifeGoals = lifeCustomerDetails.lifeGoals.length > 0
      ? this.getCommaSeperatedString(this.getStringValues(lifeCustomerDetails.lifeGoals))
      : '?';
    const annualIncome = lifeCustomerDetails.annualIncome ? lifeCustomerDetails.annualIncome : '?';
    const healthInsurance = lifeCustomerDetails.healthInsurance === 'yes' ? ' and existing life insurance.' : '';

    // Construct the summary
    const summary = `${age} years old, ${gender}, living in a ${location}, has ${familyMembers}; 
    Planning for ${lifeGoals}; Has annual income of ${annualIncome}${healthInsurance}`;

    return summary;
  }

  getCustomerSummaryForHealth(age: number, healthCustomerDetails: any): string {
    // Build the summary string
    const ageString = age > 0 ? age : '?';
    const gender = healthCustomerDetails.gender ? healthCustomerDetails.gender : '?';
    const location = healthCustomerDetails.location ? healthCustomerDetails.location : '?';
    const selectedMembers = healthCustomerDetails.members.length > 0 && healthCustomerDetails.members[0].relationship !== ''
      ? this.getCommaSeperatedString(this.getSelectedMembers(healthCustomerDetails.members))
      : '?';
    const healthConditions = healthCustomerDetails.healthConditions.length > 0
      ? this.getCommaSeperatedString(this.getStringValues(healthCustomerDetails.healthConditions))
      : '?';
    const insuranceString = this.getInsuranceString() ? this.getInsuranceString() : '?';

    // Construct the summary
    const healthSummary = `${ageString} years old, ${gender}, living in a ${location}; Looking to cover ${selectedMembers} with ${healthConditions}; Has ${insuranceString} health insurance.`;

    return healthSummary;
  }



  responseLiked() {
    let userDetails = "";
    if (!this.pointersLiked && !this.pointersDisliked) {
      this.isSkipped ? userDetails = this.inputValue : this.selectedProduct.productType === 'life' ? userDetails = this.getCustomerSummaryForLife(this.lifeCustomerDetails) : userDetails = this.getCustomerSummaryForHealth(this.age, this.healthCustomerDetails)
      let payLoad = {
        session_id: sessionStorage.getItem("sessionId"),
        session_type: "pitch",
        category: this.selectedProduct.productType,
        company: this.selectedProduct.company,
        product: this.selectedProduct.product,
        user_input: userDetails,
        model_response: this.pointsResponse.pitch_html,
        feedback_type: "positive",
        feedback_category: "Accurate"
      }
      this.apiService.addFeedback(payLoad).subscribe({
        next: (res: any) => {
          this.pointersLiked = true;
        },
        error: (err: any) => {
        }
      })
    }
  }

  // Define slides array
  slides = [1, 2, 3, 4, 5, 6, 7, 8]; // Example numbers for now

  handleCarouselMove(positive: boolean = true) {
    const carousel = document.querySelector('.carousel-container') as HTMLElement;
    const slide = document.querySelector('.carousel-slide') as HTMLElement;

    if (!carousel || !slide) {
      console.error('Carousel or slide element not found');
      return;
    }

    const slideWidth = slide.clientWidth;
    carousel.scrollLeft = positive ? carousel.scrollLeft + slideWidth : carousel.scrollLeft - slideWidth;
  }

  responseDisLiked() {
    let userDetails = "";
    if (!this.pointersLiked && !this.pointersDisliked) {
      this.isSkipped ? userDetails = this.inputValue : this.selectedProduct.productType === 'life' ? userDetails = this.getCustomerSummaryForLife(this.lifeCustomerDetails) : userDetails = this.getCustomerSummaryForHealth(this.age, this.healthCustomerDetails)
      let payLoad = {
        session_id: sessionStorage.getItem("sessionId"),
        session_type: "pitch",
        category: this.selectedProduct.productType,
        company: this.selectedProduct.company,
        product: this.selectedProduct.product,
        user_input: userDetails,
        model_response: this.pointsResponse.pitch_html,
        feedback_type: "negative",
        feedback_category: this.getSelectedReasons(),
        feedback_text: this.feedback
      }
      this.apiService.addFeedback(payLoad).subscribe({
        next: (res: any) => {
          this.pointersDisliked = true;
          this.display = false;
          this.reason1 = false;
          this.reason2 = false;
          this.reason3 = false;
          this.reason4 = false;
          this.others = false;
          this.feedback = "";
        },
        error: (err: any) => {
        }
      })
    }
  }

  showMoreDetails(recommendation: any) {
    this.selectedRecommendationPoints = recommendation.key_features;
    this.selectedRecommendationCompany = recommendation;
    this.isKnowMoreDialogOpen = true;
  }

  selectedRecommendation: number | null = null;

  // Method to select a recommendation
  selectRecommendation(index: number, product: any) {
    this.selectedRecommendation = index;
    this.selectedProductFromRecommendation = product;
    this.generateCompareDropdownOptions(this.selectedProduct.productType);
    this.selectedProduct.company = product.company;
    this.selectedProduct.product = product.product;
  }

  isCompareButtonDisabled() {
    if (this.selectedProductToCompare === '' && this.selectedRecommendation === null)
      return 'bg-gray';
    else if (this.selectedProductToCompare === '' && !this.selectedRecommendation === null)
      return 'bg-purple'
    else
      return 'bg-green'
  }

  generateCompareDropdownOptions(productType: String) {
    if (productType === 'life') {
      this.commonService.lifeProducts.subscribe((products) => {
        this.compareProductsOptions = products.filter(x => x.product != this.selectedProductFromRecommendation.product)
      })
    }
    else {
      this.commonService.healthProducts.subscribe((products) => {
        this.compareProductsOptions = products.filter(x => x.product != this.selectedProductFromRecommendation.product)
      })
    }
  }

  onModuleSelection(module: String) {
    this.commonService.salesPointers.next([]);
    this.router.navigate(['/vigi/' + module]);
    this.updateHistory();
  }

  goBack() {
    if (this.lastRoute === 'vigi/product-recommendation') {
      this.history.pop();
      this.commonService.history.next(this.history);
      this.router.navigate([this.history[this.history.length - 1]]);
    }
    else
      this.router.navigate([this.lastRoute]);
  }

  updateHistory() {
    if(this.lastRoute != "vigi/product-recommendation"){
      this.history.push("vigi/product-recommendation");
      this.commonService.setHistory(this.history);
    }
  }

  ngOnDestroy() {
    this.$historySubscription.unsubscribe();
    this.$recommendationProductsSubscription.unsubscribe();
    this.$profileSubscription.unsubscribe();
  }
}