import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './views/home/home.component';
import { AudioGenerationComponent } from './views/audio-generation/audio-generation.component';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' }, // Redirect root to Home
  { path: 'home', component: HomeComponent },
  { path: 'audio', component: AudioGenerationComponent },
  {
    path: 'vigi',
    loadChildren: () =>
      import('./views/vigi/vigi.module').then((m) => m.VigiModule),
  },
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
