<header>
    <div class="container mx-auto flex items-center justify-between">
      <!-- Logo and Name -->
        <span class="text-xl mx-auto font-bold">VIGI</span>
    </div>
  </header>
  <div class="title-section h-10 flex items-center">
    <span class="ml-9">Audio Generation</span>
  </div>
  <div class="mt-9 ml-8 mb-4 flex items-center">
    <p>Sample Business Illustration</p>
    <a href="../../../assets/document/bandhan-illustration.pdf" download="bandhan-illustration.pdf">
      <img class="h-5 w-5 ml-6" src="../../../assets/icons/list.png" alt="Download" />
    </a>
  </div>
  <img class="h-36 w-36 mx-auto mt-14" src="../../../assets/icons/bot.png"/>
  <audio #audioPlayer class="mx-auto mt-7" controls></audio>