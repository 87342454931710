import { Component, ElementRef, ViewChild } from '@angular/core';

@Component({
  selector: 'app-audio-generation',
  templateUrl: './audio-generation.component.html',
  styleUrls: ['./audio-generation.component.css']
})
export class AudioGenerationComponent {

  @ViewChild('audioPlayer') audioPlayer!: ElementRef<HTMLAudioElement>;

  ngAfterViewInit(): void {
    if (this.audioPlayer) {
      this.audioPlayer.nativeElement.innerHTML = `
        <source src="../../../assets/audio/Audio file for illustration.mp3" type="audio/mpeg">
        Your browser does not support the audio element.
      `;
      this.audioPlayer.nativeElement.setAttribute('controlsList', 'nodownload noplaybackrate');
    }
  }

}
