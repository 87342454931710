import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { VigiComponent } from './views/vigi/vigi.component';
import { HomeComponent } from './views/home/home.component';
import { VigiModule } from './views/vigi/vigi.module';
import { SalesPitchComponent } from './views/vigi/sales-pitch/sales-pitch.component';
import { ProductSelectionComponent } from './views/vigi/product-selection/product-selection.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MultiSelectModule } from 'primeng/multiselect';
import { ProductDetailsLifeComponent } from './views/vigi/sales-pitch/product-details-life/product-details-life.component';
import { ProductDetailsHealthComponent } from './views/vigi/sales-pitch/product-details-health/product-details-health.component';
import { DialogModule } from 'primeng/dialog';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { HttpClientModule } from '@angular/common/http';
import { CommaFormatterDirective } from './Directives/comma-formatter.directive';
import { ProductQueriesComponent } from './views/vigi/product-queries/product-queries.component';
import { ErrorDialogComponent } from './error-dialog/error-dialog.component';
import { DialogService } from 'primeng/dynamicdialog';
import { ProductRecommendationComponent } from './views/vigi/product-recommendation/product-recommendation.component';
import { GoogleApiService } from './services/google-api.service';
import { AudioGenerationComponent } from './views/audio-generation/audio-generation.component';

@NgModule({
  declarations: [
    AppComponent,
    VigiComponent,
    HomeComponent,
    SalesPitchComponent,
    ProductSelectionComponent,
    ProductDetailsLifeComponent,
    ProductDetailsHealthComponent,
    CommaFormatterDirective,
    ProductQueriesComponent,
    ErrorDialogComponent,
    ProductRecommendationComponent,
    AudioGenerationComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    VigiModule,
    BrowserAnimationsModule,
    DropdownModule,
    AppRoutingModule,
    MultiSelectModule,
    DialogModule,
    NgMultiSelectDropDownModule.forRoot(),
    HttpClientModule,
  ],
  providers: [DialogService,
    GoogleApiService],
  bootstrap: [AppComponent]
})
export class AppModule { }
