import { Component, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from './services/api.service';
import { CommonService } from './services/common.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'vigi-ui';
  private inactivityTimer: any;
  private readonly inactivityLimit = 10 * 60 * 1000; // 10 minutes in milliseconds

  constructor(private router: Router, private apiService: ApiService, commonService: CommonService){
    commonService.productSelection.subscribe((res)=>{
      this.resetSessionId();
    })
  }

  ngOnInit() {
    this.resetInactivityTimer();
    this.getSessionId();
  }

  getSessionId() {
    let sessionId = sessionStorage.getItem('sessionId');
    if (!sessionId) {
    sessionId = this.generateRandomString(16); // Generate a random 16-character string
    sessionStorage.setItem('sessionId', sessionId);
    }
    return sessionId;
  }

  resetSessionId() {
    sessionStorage.removeItem('sessionId'); // Remove the current session ID
    const newSessionId = this.generateRandomString(16); // Generate a new random session ID
    sessionStorage.setItem('sessionId', newSessionId); // Set the new session ID
  }

  generateRandomString(length) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  ngOnDestroy() {
    if (this.inactivityTimer) {
      clearTimeout(this.inactivityTimer);
    }
  }

  @HostListener('document:mousemove')
  @HostListener('document:keypress')
  resetInactivityTimer() {
    if (this.inactivityTimer) {
      clearTimeout(this.inactivityTimer);
    }
    this.inactivityTimer = setTimeout(() => this.resetSessionId(), this.inactivityLimit);
  }
}
