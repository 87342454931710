import { Component } from '@angular/core';

@Component({
  selector: 'app-video-generation',
  templateUrl: './video-generation.component.html',
  styleUrls: ['./video-generation.component.css']
})
export class VideoGenerationComponent {

}
