<header>
  <div class="container mx-auto flex items-center justify-between">
    <!-- Logo and Name -->
    <div class="flex items-center">
      <button (click)="goBack()" class="focus:outline-none">
        <svg class="h-8 w-8 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 12H5M12 19l-7-7 7-7"></path>
        </svg>
      </button>
      <span class="text-xl ml-3 font-bold">VIGI</span>
    </div>
    <!-- Kebab Icon -->
    <div class="flex items-center">
      <button (onblur)="toggleDropdown()" (click)="toggleDropdown()" class="focus:outline-none toggle-button">
        <img class="h-6 w-6" src="../../../../assets/icons/menu.png" />
      </button>
      <div [ngClass]="{'dropdown-open': isDropdownOpen}"
        class="header-wrapper absolute bg-white text-black rounded-md shadow-lg overflow-hidden">
        <div class="navigation-options">
          <span routerLink="/home" class="block px-4 py-2 text-sm">Exit</span>
        </div>
      </div>
    </div>
  </div>
</header>
<form [formGroup]="productForm" (ngSubmit)="onSubmit()">
  <div class="container flex justify-center mt-7 mb-7">
    <label for="Toggle3" class="inline-flex module-toggle items-center cursor-pointer justify-center">
      <input id="Toggle3" type="checkbox" class="hidden">

      <!-- Product Recommendation -->
      <span [ngClass]="{
              'bg-purple text-white rounded-l-sm': productForm.get('moduleType').value === 'product-recommendation',
              'bg-gray text-black rounded-l-md': productForm.get('moduleType').value !== 'product-recommendation'
            }" class="flex items-center justify-center px-2 py-2 text-center"
        (click)="toggleModuleSelection('product-recommendation')">
        Product Recommendation
      </span>

      <!-- Sales Pointers -->
      <span [ngClass]="{
              'bg-purple text-white': productForm.get('moduleType').value === 'sales-pitch',
              'bg-gray text-black': productForm.get('moduleType').value !== 'sales-pitch'
            }" class="flex items-center justify-center px-2 py-2 text-center"
        (click)="toggleModuleSelection('sales-pitch')">
        Sales Pointers & Product Comparison
      </span>

      <!-- Product Queries -->
      <span [ngClass]="{
          'bg-purple text-white rounded-r-sm': productForm.get('moduleType').value === 'product-queries',
          'bg-gray text-black rounded-r-md': productForm.get('moduleType').value !== 'product-queries'
        }" class="flex items-center justify-center px-2 py-2 text-center"
        (click)="toggleModuleSelection('product-queries')">
        Product Queries
      </span>
    </label>
  </div>
  <div *ngIf="showForm">
    <div class="container flex justify-center mt-7 mb-7">
      <label for="Toggle3" class="inline-flex product-toggle items-center cursor-pointer justify-center">
        <input id="Toggle3" type="checkbox" class="hidden" formControlName="productType">
        <span [ngClass]="{
              'bg-purple text-white': !isHealthSelected,
              'bg-gray text-black': isHealthSelected
            }" class="px-4 py-2 w-1/2 text-center rounded-l-md"
            (click)="toggleSelection()">
          Life
        </span>
        <span [ngClass]="{
              'bg-purple text-white': isHealthSelected,
              'bg-gray text-black': !isHealthSelected
            }" class="px-4 py-2 w-1/2 text-center rounded-r-md"
            (click)="toggleSelection()">
          Health
        </span>
      </label>
    </div>
    <div class="container mx-auto p-4">

      <!-- Dropdown Container -->
      <div class="dropdown-container flex items-center rounded pl-2 mb-4 w-full h-10">
        <!-- Umbrella Icon -->
        <img src="../../../../assets/icons/life-insurance.png" alt="Placeholder" class="vigi-icon mx-auto">
        <!-- Dropdown -->
        <div class="w-full">
          <p-dropdown id="product" formControlName="product" (onChange)="onProductChange($event)" [options]="products"
            placeholder="Select a product"></p-dropdown>
        </div>
      </div>

    </div>
  </div>
  <div *ngIf="showOptions" class="flex justify-center items-center space-x-9 mt-9 sales-product-toggle">
      <label class="flex items-center justify-center cursor-pointer border rounded border-gray-300">
        <input type="radio" (change)="onTypeSelectionForProductRecommendation('life')" class="form-radio">
        <span class="ml-2">Life Insurance</span>
      </label>
      <label class="flex items-center justify-center cursor-pointer border rounded border-gray-300">
        <input type="radio" (change)="onTypeSelectionForProductRecommendation('health')"
          class="form-radio">
        <span class="ml-2">Health Insurance</span>
      </label>
    </div>
    <div *ngIf="productForm.get('productType')?.invalid && productForm.get('productType')?.touched"
      class="text-red-500 text-center mt-4">
      Please select anyone
    </div>
</form>