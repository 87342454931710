import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  public productSelection = new BehaviorSubject<String>("");
  public lifeProducts = new BehaviorSubject<Array<any>>([]);
  public healthProducts = new BehaviorSubject<Array<any>>(null);
  public salesPointers = new BehaviorSubject<Array<any>>(null);
  public customerProfile = new BehaviorSubject<any>({
    profile: null,
    string: null,
    from: null
  });
  public productRecommendation = new BehaviorSubject<any>(null);
  public history = new BehaviorSubject<Array<String>>([]);
  public isVoiceModeEnabled = new BehaviorSubject<boolean>(false);
  constructor() { }

  setProduct(args: any){
    this.productSelection.next(args);
  }

  setLifeProducts(args: any){
    this.lifeProducts.next(args);
  }

  setHealthProducts(args: any){
    this.healthProducts.next(args);
  }

  setSalesPointers(args: any){
    this.salesPointers.next(args);
  }

  setHistory(args: any){
    this.history.next(args);
  }

  setCustomerProfile(args: any){
    this.customerProfile.next(args);
  }

  setProductRecommendations(args: any){
    this.productRecommendation.next(args);
  }

  resetAllSubjects(){
    this.customerProfile.next({
      profile: null,
      string: null,
      from: null
    });
    this.salesPointers.next([]);
    this.productRecommendation.next([]);
    this.history.next([]);
  }
}
