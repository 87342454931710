import { Location } from '@angular/common';
import { Component, AfterViewChecked, ElementRef, ViewChild, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { DialogService } from 'primeng/dynamicdialog';
import { Subscription } from 'rxjs';
import { ErrorDialogComponent } from 'src/app/error-dialog/error-dialog.component';
import { ApiService } from 'src/app/services/api.service';
import { CommonService } from 'src/app/services/common.service';
interface MessageResponsePair {
  message: string;
  response?: string[];
  completeResponse?: any;
  loading?: boolean;
  currentPage?: number;
  totalPages?: number;
  isLiked?: boolean;
  isDisliked?: boolean;
}
@Component({
  selector: 'app-product-queries',
  templateUrl: './product-queries.component.html',
  styleUrls: ['./product-queries.component.css']
})
export class ProductQueriesComponent implements AfterViewChecked {
  inputText: string = '';
  messageResponsePairs: MessageResponsePair[] = [];
  private subscription: Subscription;
  pointersLiked: boolean = false;
  pointersDisliked: boolean = false;
  isDropdownOpen: boolean = false;
  display: boolean = false;
  reason1: boolean = false;
  reason2: boolean = false;
  reason3: boolean = false;
  reason4: boolean = false;
  others: boolean = false;
  selectedReasonsArray: string[] = [];
  selectedPairForDislike: any;
  selectedPairIndexForDislike: number;
  feedback: string = '';
  isResponseLoading: boolean = false;
  selectedProduct: any;
  @ViewChild('chatContainer') private chatContainer: ElementRef;
  apiError: boolean = false;
  history: any[] = [];
  $historySubscription: Subscription;

  constructor(public commonService: CommonService, private apiService: ApiService,
    private dialogService: DialogService, private router: Router
  ) {
    commonService.productSelection.subscribe((product) => {
      this.selectedProduct = product;
    })
    this.$historySubscription = this.commonService.history.subscribe((res)=>{
      this.history = res;
    })
  }

  adjustTextareaHeight(event: Event) {
    const textarea = event.target as HTMLTextAreaElement;
    textarea.style.height = 'auto';
    textarea.style.height = `${textarea.scrollHeight}px`;
  }

  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  sendMessage(textarea: HTMLTextAreaElement) {
    if (this.inputText.trim() !== '') {
      // Add the user's message
      const newPair: MessageResponsePair = { message: this.inputText.trim(), loading: true };
      this.messageResponsePairs.push(newPair);
      this.isResponseLoading = true;

      // Reset the textarea height to 40px after sending the message
      setTimeout(() => {
        textarea.style.height = '40px';
      }, 0);

      // Simulate a response after 2 seconds
      let payLoad = {
        session_id: sessionStorage.getItem('sessionId'),
        query: this.inputText.trim()
      }
      const startTime = Date.now();
      this.subscription = this.apiService.getProductResponse(payLoad, this.selectedProduct.company, this.selectedProduct.product, this.selectedProduct.productType).subscribe({
        next: (res) => {
          this.inputText = "";
          newPair.loading = false;
          newPair.response = (this.splitTextIntoChunks(this.formatTextToHTML(res.response), 150));
          newPair.completeResponse = res.response;
          newPair.totalPages = newPair.response.length;
          newPair.currentPage = 0;
          // Ensure the view is updated before scrolling
          this.isResponseLoading = false;
          setTimeout(() => {
            textarea.style.height = '40px';
            document.getElementById('final').scrollIntoView();
          }, 0);
          const endTime = Date.now();
        },
        error: (err) => {
          this.isResponseLoading = false;
          this.showErrorDialog();
          newPair.loading = false;
        }
      })
    }
  }



  resetTextareaHeight() {
    const textarea = document.querySelector('textarea');
    if (textarea) {
      textarea.style.height = 'auto';
    }
  }

  ngAfterViewChecked() {
    this.scrollToBottom();
  }

  scrollToBottom(): void {
    try {
      this.chatContainer.nativeElement.scrollTop = this.chatContainer.nativeElement.scrollHeight;
    } catch (err) { }
  }

  showDialog(pair, index) {
    if(!this.messageResponsePairs[index].isLiked && !this.messageResponsePairs[index].isDisliked){
    this.selectedPairIndexForDislike = index;
    this.selectedPairForDislike = pair;
    this.display = true;
    }
  }

  onSubmit() {

  }

  stopAPI() {
    this.subscription.unsubscribe();
    this.isResponseLoading = false;
    this.messageResponsePairs[this.messageResponsePairs.length - 1].loading = false;
    this.messageResponsePairs[this.messageResponsePairs.length - 1].response = [];
  }

  formatTextToHTML(text) {
    // Replace new lines with <br/> tags
    let formattedText = text.replace(/\n\n/g, '<br/><br/>');
    formattedText = formattedText.replace(/\n/g, '<br/>')
    // Replace strings enclosed within "**" with <b> tags
    formattedText = formattedText.replace(/\*\*(.*?)\*\*/g, '<b>$1</b>');
    return formattedText;
  }

  splitTextIntoChunks(text, wordLimit) {
    const sections = text.split(/(\*\*.*?\*\*|\n\n|<br\/>)/g); // Split by bold tags, newlines, and <br/>
    let chunks = [];
    let currentChunk = '';

    sections.forEach(section => {
      let tempChunk = currentChunk + section;

      // Check if adding this section would exceed the word limit
      if (tempChunk.split(' ').length > wordLimit) {
        // If the current section is bold, ensure it's not at the end of the current chunk
        if (section.includes('<b>')) {
          chunks.push(currentChunk.trim());
          currentChunk = section;
        } else {
          // Move the entire section to the next chunk
          if (currentChunk) {
            chunks.push(currentChunk.trim());
          }
          currentChunk = section;
        }
      } else {
        currentChunk = tempChunk;
      }
    });

    // Add any remaining content
    if (currentChunk) {
      chunks.push(currentChunk.trim());
    }

    return chunks;
  }

  previousPage(index): void {
    if (this.messageResponsePairs[index].currentPage! > 0) {
      this.messageResponsePairs[index].currentPage!--;
    }
  }

  nextPage(index): void {
    if (this.messageResponsePairs[index].currentPage! < this.messageResponsePairs[index].totalPages! - 1) {
      this.messageResponsePairs[index].currentPage!++;
    }
  }

  showErrorDialog() {
    this.dialogService.open(ErrorDialogComponent, {
      width: '70%'
    });
  }

  getSelectedReasons() {
    // Handle 'Not relevant'
    if (this.reason1 && !this.selectedReasonsArray.includes('Not relevant')) {
      this.selectedReasonsArray.push('Not relevant');
    } else if (!this.reason1) {
      this.selectedReasonsArray = this.selectedReasonsArray.filter(reason => reason !== 'Not relevant');
    }
  
    // Handle 'Not accurate'
    if (this.reason2 && !this.selectedReasonsArray.includes('Not accurate')) {
      this.selectedReasonsArray.push('Not accurate');
    } else if (!this.reason2) {
      this.selectedReasonsArray = this.selectedReasonsArray.filter(reason => reason !== 'Not accurate');
    }
  
    // Handle 'Repetitive'
    if (this.reason3 && !this.selectedReasonsArray.includes('Repetitive')) {
      this.selectedReasonsArray.push('Repetitive');
    } else if (!this.reason3) {
      this.selectedReasonsArray = this.selectedReasonsArray.filter(reason => reason !== 'Repetitive');
    }
  
    // Handle "Can't understand"
    if (this.reason4 && !this.selectedReasonsArray.includes("Can't understand")) {
      this.selectedReasonsArray.push("Can't understand");
    } else if (!this.reason4) {
      this.selectedReasonsArray = this.selectedReasonsArray.filter(reason => reason !== "Can't understand");
    }
  
    // Handle 'Others'
    if (this.others && !this.selectedReasonsArray.includes('Others')) {
      this.selectedReasonsArray.push('Others');
    } else if (!this.others) {
      this.selectedReasonsArray = this.selectedReasonsArray.filter(reason => reason !== 'Others');
    }
  
    // Join the selected reasons into a single string
    const selectedReasons = this.selectedReasonsArray.join(', ');
  
    return selectedReasons;
  }

  responseLiked(pair: any, index:number) {
    if(!this.messageResponsePairs[index].isLiked && !this.messageResponsePairs[index].isDisliked){
      let payLoad = {
        session_id: sessionStorage.getItem("sessionId"),
        session_type: "query",
        category: this.selectedProduct.productType,
        company: this.selectedProduct.company,
        product: this.selectedProduct.product,
        user_input: pair.message,
        model_response: pair.completeResponse,
        feedback_type: "positive",
        feedback_category: "Accurate"
      }
      this.apiService.addFeedback(payLoad).subscribe({
        next:(res:any)=>{
          this.messageResponsePairs[index].isLiked = true;
        },
        error:(err:any)=>{
        }
      })    
    }
  }

  responseDisLiked() {
    let pair = this.selectedPairForDislike;
    let index = this.selectedPairIndexForDislike;
    if(!this.messageResponsePairs[index].isLiked && !this.messageResponsePairs[index].isDisliked){
      let payLoad = {
        session_id: sessionStorage.getItem("sessionId"),
        session_type: "query",
        category: this.selectedProduct.productType,
        company: this.selectedProduct.company,
        product: this.selectedProduct.product,
        user_input: pair.message,
        model_response: pair.completeResponse,
        feedback_type: "negative",
        feedback_category: this.getSelectedReasons(),
        feedback_text: this.feedback
      }
      this.apiService.addFeedback(payLoad).subscribe({
        next:(res:any)=>{
          this.messageResponsePairs[index].isDisliked = true;
          this.display = false;
          this.reason1 = false;
          this.reason2 = false;
          this.reason3 = false;
          this.reason4 = false;
          this.others = false;
          this.feedback = "";
        },
        error:(err:any)=>{
        }
      })    
    }
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const target = event.target as HTMLElement;
    const clickedInside = target.closest('.navigation-options') || target.closest('.toggle-button');

    if (!clickedInside && this.isDropdownOpen) {
      this.isDropdownOpen = false;
    }
  }

  goBack(){
    let lastRoute = this.history[this.history.length-1];
    this.router.navigate([lastRoute]);
  }

  ngOnDestroy(){
    this.$historySubscription.unsubscribe();
  }

}
