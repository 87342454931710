import { Component } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent {
  isVoiceEnabled: boolean = false; // Default: No

  constructor(private commonService: CommonService){
    commonService.resetAllSubjects();
  }

  ngOnDestroy(){
    this.commonService.isVoiceModeEnabled.next(this.isVoiceEnabled);
  }
}
