import { Location } from '@angular/common';
import { Component, AfterViewChecked, ElementRef, ViewChild, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { DialogService } from 'primeng/dynamicdialog';
import { Subscription } from 'rxjs';
import { ErrorDialogComponent } from 'src/app/error-dialog/error-dialog.component';
import { ApiService } from 'src/app/services/api.service';
import { CommonService } from 'src/app/services/common.service';
import { GoogleApiService } from '../../../services/google-api.service';

interface MessageResponsePair {
  message: string;
  response?: string[];
  completeResponse?: any;
  loading?: boolean;
  currentPage?: number;
  totalPages?: number;
  isLiked?: boolean;
  isDisliked?: boolean;
}
@Component({
  selector: 'app-product-queries',
  templateUrl: './product-queries.component.html',
  styleUrls: ['./product-queries.component.css']
})
export class ProductQueriesComponent implements AfterViewChecked {
  inputValue: string = ''; 
  translatedText: string = ''; // Translated text
  isListening: boolean = false; // Tracks if listening
  voiceError: string = ''; // Error messages for voice
  recognition: any; 
  processedResults: Set<string> = new Set();
  debounceTimeout: any = null;
  combinedTranscript: string = '';


  inputText: string = '';
  messageResponsePairs: MessageResponsePair[] = [];
  private subscription: Subscription;
  pointersLiked: boolean = false;
  pointersDisliked: boolean = false;
  isDropdownOpen: boolean = false;
  display: boolean = false;
  reason1: boolean = false;
  reason2: boolean = false;
  reason3: boolean = false;
  reason4: boolean = false;
  others: boolean = false;
  selectedReasonsArray: string[] = [];
  selectedPairForDislike: any;
  selectedPairIndexForDislike: number;
  feedback: string = '';
  isResponseLoading: boolean = false;
  selectedProduct: any;
  @ViewChild('chatContainer') private chatContainer: ElementRef;
  apiError: boolean = false;
  history: any[] = [];
  $historySubscription: Subscription;
  isVoiceEnabled: boolean = false;
  $voiceSubscription: Subscription;

  constructor(public commonService: CommonService, private apiService: ApiService,
    private dialogService: DialogService, private router: Router, private googleApi: GoogleApiService
  ) {
    commonService.productSelection.subscribe((product) => {
      this.selectedProduct = product;
      if(product==="")
        router.navigate(["/home"]);
    })
    this.$historySubscription = this.commonService.history.subscribe((res)=>{
      this.history = res;
    })
    this.$voiceSubscription = commonService.isVoiceModeEnabled.subscribe((res)=>{
      this.isVoiceEnabled = res;
    })
  }

  adjustTextareaHeight(event: Event) {
    const textarea = event.target as HTMLTextAreaElement;
    textarea.style.height = 'auto';
    textarea.style.height = `${textarea.scrollHeight}px`;
  }

  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  sendMessage(textarea: HTMLTextAreaElement) {
    if (this.inputText.trim() !== '') {
      // Add the user's message
      const newPair: MessageResponsePair = { message: this.inputText.trim(), loading: true };
      this.messageResponsePairs.push(newPair);
      this.isResponseLoading = true;

      // Reset the textarea height to 40px after sending the message
      setTimeout(() => {
        textarea.style.height = '40px';
      }, 0);

      // Simulate a response after 2 seconds
      let payLoad = {
        session_id: sessionStorage.getItem('sessionId'),
        query: this.inputText.trim()
      }
      const startTime = Date.now();
      this.subscription = this.apiService.getProductResponse(payLoad, this.selectedProduct.company, this.selectedProduct.product, this.selectedProduct.productType).subscribe({
        next: (res) => {
          this.inputText = "";
          newPair.loading = false;
          newPair.response = (this.splitTextIntoChunks(this.formatTextToHTML(res.response), 150));
          newPair.completeResponse = res.response;
          newPair.totalPages = newPair.response.length;
          newPair.currentPage = 0;
          // Ensure the view is updated before scrolling
          this.isResponseLoading = false;
          setTimeout(() => {
            textarea.style.height = '40px';
            document.getElementById('final').scrollIntoView();
          }, 0);
          const endTime = Date.now();
        },
        error: (err) => {
          this.isResponseLoading = false;
          this.showErrorDialog();
          newPair.loading = false;
        }
      })
    }
  }



  resetTextareaHeight() {
    const textarea = document.querySelector('textarea');
    if (textarea) {
      textarea.style.height = 'auto';
    }
  }

  ngAfterViewChecked() {
    this.scrollToBottom();
  }

  scrollToBottom(): void {
    try {
      this.chatContainer.nativeElement.scrollTop = this.chatContainer.nativeElement.scrollHeight;
    } catch (err) { }
  }

  showDialog(pair, index) {
    if(!this.messageResponsePairs[index].isLiked && !this.messageResponsePairs[index].isDisliked){
    this.selectedPairIndexForDislike = index;
    this.selectedPairForDislike = pair;
    this.display = true;
    }
  }

  onSubmit() {

  }

  stopAPI() {
    this.subscription.unsubscribe();
    this.isResponseLoading = false;
    this.messageResponsePairs[this.messageResponsePairs.length - 1].loading = false;
    this.messageResponsePairs[this.messageResponsePairs.length - 1].response = [];
  }

  formatTextToHTML(text) {
    // Replace new lines with <br/> tags
    let formattedText = text.replace(/\n\n/g, '<br/><br/>');
    formattedText = formattedText.replace(/\n/g, '<br/>')
    // Replace strings enclosed within "**" with <b> tags
    formattedText = formattedText.replace(/\*\*(.*?)\*\*/g, '<b>$1</b>');
    return formattedText;
  }

  splitTextIntoChunks(text, wordLimit) {
    const sections = text.split(/(\*\*.*?\*\*|\n\n|<br\/>)/g); // Split by bold tags, newlines, and <br/>
    let chunks = [];
    let currentChunk = '';

    sections.forEach(section => {
      let tempChunk = currentChunk + section;

      // Check if adding this section would exceed the word limit
      if (tempChunk.split(' ').length > wordLimit) {
        // If the current section is bold, ensure it's not at the end of the current chunk
        if (section.includes('<b>')) {
          chunks.push(currentChunk.trim());
          currentChunk = section;
        } else {
          // Move the entire section to the next chunk
          if (currentChunk) {
            chunks.push(currentChunk.trim());
          }
          currentChunk = section;
        }
      } else {
        currentChunk = tempChunk;
      }
    });

    // Add any remaining content
    if (currentChunk) {
      chunks.push(currentChunk.trim());
    }

    return chunks;
  }

  previousPage(index): void {
    if (this.messageResponsePairs[index].currentPage! > 0) {
      this.messageResponsePairs[index].currentPage!--;
    }
  }

  nextPage(index): void {
    if (this.messageResponsePairs[index].currentPage! < this.messageResponsePairs[index].totalPages! - 1) {
      this.messageResponsePairs[index].currentPage!++;
    }
  }

  showErrorDialog() {
    this.dialogService.open(ErrorDialogComponent, {
      width: '70%'
    });
  }

  getSelectedReasons() {
    // Handle 'Not relevant'
    if (this.reason1 && !this.selectedReasonsArray.includes('Not relevant')) {
      this.selectedReasonsArray.push('Not relevant');
    } else if (!this.reason1) {
      this.selectedReasonsArray = this.selectedReasonsArray.filter(reason => reason !== 'Not relevant');
    }
  
    // Handle 'Not accurate'
    if (this.reason2 && !this.selectedReasonsArray.includes('Not accurate')) {
      this.selectedReasonsArray.push('Not accurate');
    } else if (!this.reason2) {
      this.selectedReasonsArray = this.selectedReasonsArray.filter(reason => reason !== 'Not accurate');
    }
  
    // Handle 'Repetitive'
    if (this.reason3 && !this.selectedReasonsArray.includes('Repetitive')) {
      this.selectedReasonsArray.push('Repetitive');
    } else if (!this.reason3) {
      this.selectedReasonsArray = this.selectedReasonsArray.filter(reason => reason !== 'Repetitive');
    }
  
    // Handle "Can't understand"
    if (this.reason4 && !this.selectedReasonsArray.includes("Can't understand")) {
      this.selectedReasonsArray.push("Can't understand");
    } else if (!this.reason4) {
      this.selectedReasonsArray = this.selectedReasonsArray.filter(reason => reason !== "Can't understand");
    }
  
    // Handle 'Others'
    if (this.others && !this.selectedReasonsArray.includes('Others')) {
      this.selectedReasonsArray.push('Others');
    } else if (!this.others) {
      this.selectedReasonsArray = this.selectedReasonsArray.filter(reason => reason !== 'Others');
    }
  
    // Join the selected reasons into a single string
    const selectedReasons = this.selectedReasonsArray.join(', ');
  
    return selectedReasons;
  }

  responseLiked(pair: any, index:number) {
    if(!this.messageResponsePairs[index].isLiked && !this.messageResponsePairs[index].isDisliked){
      let payLoad = {
        session_id: sessionStorage.getItem("sessionId"),
        session_type: "query",
        category: this.selectedProduct.productType,
        company: this.selectedProduct.company,
        product: this.selectedProduct.product,
        user_input: pair.message,
        model_response: pair.completeResponse,
        feedback_type: "positive",
        feedback_category: "Accurate"
      }
      this.apiService.addFeedback(payLoad).subscribe({
        next:(res:any)=>{
          this.messageResponsePairs[index].isLiked = true;
        },
        error:(err:any)=>{
        }
      })    
    }
  }

  responseDisLiked() {
    let pair = this.selectedPairForDislike;
    let index = this.selectedPairIndexForDislike;
    if(!this.messageResponsePairs[index].isLiked && !this.messageResponsePairs[index].isDisliked){
      let payLoad = {
        session_id: sessionStorage.getItem("sessionId"),
        session_type: "query",
        category: this.selectedProduct.productType,
        company: this.selectedProduct.company,
        product: this.selectedProduct.product,
        user_input: pair.message,
        model_response: pair.completeResponse,
        feedback_type: "negative",
        feedback_category: this.getSelectedReasons(),
        feedback_text: this.feedback
      }
      this.apiService.addFeedback(payLoad).subscribe({
        next:(res:any)=>{
          this.messageResponsePairs[index].isDisliked = true;
          this.display = false;
          this.reason1 = false;
          this.reason2 = false;
          this.reason3 = false;
          this.reason4 = false;
          this.others = false;
          this.feedback = "";
        },
        error:(err:any)=>{
        }
      })    
    }
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const target = event.target as HTMLElement;
    const clickedInside = target.closest('.navigation-options') || target.closest('.toggle-button');

    if (!clickedInside && this.isDropdownOpen) {
      this.isDropdownOpen = false;
    }
  }

  goBack(){
    let lastRoute = this.history[this.history.length-1];
    this.router.navigate([lastRoute]);
  }

  ngOnDestroy(){
    this.$historySubscription.unsubscribe();
    this.$voiceSubscription.unsubscribe();
  }

 // Toggle voice recognition
toggleVoiceRecognition() {
  if (this.isListening) {
    this.stopVoiceRecognition();
  } else {
    this.startVoiceRecognition();
  }
}
  
  // Start voice recognition
  startVoiceRecognition() {
    const SpeechRecognition =
      (window as any).SpeechRecognition || (window as any).webkitSpeechRecognition;

    if (!SpeechRecognition) {
      this.voiceError = 'Speech recognition is not supported in your browser.';
      console.log(this.voiceError);
      return;
    }

    this.recognition = new SpeechRecognition();
    this.recognition.lang = 'en-IN'; // English and Hindi detection
    this.recognition.interimResults = true; // Show interim results
    this.recognition.continuous = true; // Continuous listening

    this.isListening = true;
    this.voiceError = '';
    this.processedResults.clear();
    this.debounceTimeout = null;
    this.combinedTranscript = '';

    console.log('Voice recognition started.');

    this.recognition.start();

    this.recognition.onresult = (event: any) => {
      console.log('Speech recognition result:', event);

      for (let i = event.resultIndex; i < event.results.length; i++) {
        const transcript = event.results[i][0].transcript.trim();

        console.log(
          `Transcript: "${transcript}", isFinal: ${event.results[i].isFinal}`
        );

        if (event.results[i].isFinal) {
          // Process only final results to avoid duplicate and partial transcripts
          clearTimeout(this.debounceTimeout);
          this.debounceTimeout = setTimeout(() => {
            if (!this.processedResults.has(transcript)) {
              this.processedResults.add(transcript);
              this.processAudio(transcript); // Process final transcript
            } else {
              console.log(`Skipping duplicate transcript: "${transcript}"`);
            }
          }, 1000); // Adjust debounce delay as needed
        } else {
         
          console.log('Interim transcript:', transcript);
        }
      }
    };

    this.recognition.onerror = (event: any) => {
      this.voiceError = 'Speech recognition error: ' + event.error;
      console.error(this.voiceError);
      this.stopVoiceRecognition();
    };

    this.recognition.onend = () => {
      this.isListening = false;
      console.log('Voice recognition stopped.');
    };
  }

  // Stop voice recognition
  stopVoiceRecognition() {
    if (this.recognition) {
      this.recognition.stop();
      this.isListening = false;
      console.log('Voice recognition manually stopped.');
    }
  }

  // Process audio transcript
  processAudio(transcript: string) {
    console.log('Processing transcript:', transcript);
    if (this.detectLanguage(transcript) === 'hi') {
      // Append Hindi text to the input field first
      this.inputText += transcript + ' ';
      console.log('Detected Hindi. Translating to English...');
  
      // Translate to English
      this.googleApi.translateText(transcript, 'en').subscribe({
        next: (response: any) => {
          const translatedText = response.data.translations[0].translatedText;
          this.inputText += translatedText + ' '; 
          console.log('Translation successful:', translatedText);
        },
        error: (err) => {
          console.error('Translation Error:', err);
          this.voiceError = 'Failed to translate text.';
        },
      });
    } else {
      // For English, directly update the input box
      this.inputText += transcript + ' ';
      console.log('Detected English. Added directly to input.');
    }
  }
  
  // Detect language (simple logic based on character patterns)
  detectLanguage(text: string): string {
    const hindiRegex = /[\u0900-\u097F]/;
    const detectedLanguage = hindiRegex.test(text) ? 'hi' : 'en';
    console.log(`Language detected: ${detectedLanguage}`);
    return detectedLanguage;
  }

  // Translate text manually
translateText() {
 
  if (!this.inputText || this.inputText.trim() === '') {
    this.voiceError = 'No text to translate!';
    console.log(this.voiceError);
    return;
  }

  console.log('Manual translation started.');
  this.googleApi.translateText(this.inputText, 'en').subscribe({
    next: (response: any) => {
      const translatedText = response.data.translations[0].translatedText;
      this.inputValue = translatedText;
      console.log('Manual translation successful:', translatedText);
    },
    error: (err) => {
      console.error('Translation Error:', err);
      this.voiceError = 'Failed to translate text.';
    },
  });
}
}