<header>
    <div class="header flex items-center">
        <span class="font-bold">Insurance Sales App</span>
    </div>
</header>
<main>
    <img src="../../../assets/icons/blurred-bg.png" />
    <div class="vigi-icon-container">
        <img routerLink="/vigi" class="vigi-icon mx-auto" src="../../../assets/icons/bot-icon.png" />
        <span class="font-medium text-xs">VIGI</span>
    </div>
    <div class="mt-4 flex flex-col items-center justify-center text-center gap-2">
        <span class="text-xs font-medium">Do you want to enable the voice feature?</span>
        <label class="flex items-center cursor-pointer">
            <span class="mr-2 text-sm">No</span>
            <input type="checkbox" [(ngModel)]="isVoiceEnabled" class="sr-only">
            <div class="w-10 h-5 bg-gray-300 rounded-full flex items-center p-1 transition-all" 
                 [class.bg-blue-500]="isVoiceEnabled">
                <div class="w-4 h-4 bg-white rounded-full shadow-md transform transition-all" 
                     [class.translate-x-5]="isVoiceEnabled"></div>
            </div>
            <span class="ml-2 text-sm">Yes</span>
        </label>
    </div>    
</main>