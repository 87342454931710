<app-product-details-life [selectedProduct]="selectedProduct" [pageName]="pageName"
  *ngIf="selectedProductType==='life' && (!showSalesPitchModule && !isSkipped)"
  (customerDetails)="customerDetailsForLife($event)"></app-product-details-life>
<app-product-details-health [selectedProduct]="selectedProduct" [pageName]="pageName"
  *ngIf="selectedProductType==='health' && (!showSalesPitchModule && !isSkipped)"
  (customerDetails)="customerDetailsForHealth($event)"></app-product-details-health>
<div *ngIf="showSalesPitchModule">
  <header>
    <div class="container mx-auto flex items-center justify-between">
      <!-- Logo and Name -->
      <div class="flex items-center">
        <button (click)="goBack()" class="focus:outline-none">
          <svg class="h-8 w-8 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 12H5M12 19l-7-7 7-7"></path>
          </svg>
        </button>
        <span class="text-xl ml-3 font-bold">Sales pitch pointers</span>
      </div>
      <!-- Kebab Icon -->
      <div class="flex items-center">
        <button (onblur)="toggleDropdown()" (click)="toggleDropdown()" class="focus:outline-none toggle-button">
          <img class="h-6 w-6" src="../../../../assets/icons/menu.png" />
        </button>
        <div [ngClass]="{'dropdown-open': isDropdownOpen}" class="header-wrapper absolute bg-white text-black rounded-md shadow-lg overflow-hidden">
          <div class="navigation-options">
            <span routerLink="/home" class="block px-4 py-2 text-sm">Exit</span>
            <span routerLink="/vigi" class="block px-4 py-2 text-sm">Change product</span>
          </div>
        </div>
      </div>
    </div>
  </header>
  <div class="title-section h-10 flex items-center">
    <span class="mx-auto">{{selectedProduct.company+' - '+selectedProduct.product}}</span>
  </div>
  <div *ngIf="selectedProductType==='life'" class="bg-white p-2">
    <div class="flex items-center">
      <h2 class="font-bold">Customer profile</h2>
      <img *ngIf="!isEditing" (click)="startEditing(); toggleEditingMode()" class="h-2 w-2 ml-2 cursor-pointer" src="../../../../assets/icons/edit-pointers.png"/>
    </div>
    <p class="text-gray-700">
      <span [ngClass]="{'missing-data': !lifeCustomerDetails.age, 'normal-data': lifeCustomerDetails.age}">
        {{lifeCustomerDetails.age ? lifeCustomerDetails.age : '?'}}
      </span> years old, 
      <span [ngClass]="{'missing-data': !lifeCustomerDetails.gender, 'normal-data': lifeCustomerDetails.gender}">
        {{lifeCustomerDetails.gender ? lifeCustomerDetails.gender : '?'}}
      </span>, living in a 
      <span [ngClass]="{'missing-data': !lifeCustomerDetails.location, 'normal-data': lifeCustomerDetails.location}">
        {{lifeCustomerDetails.location ? lifeCustomerDetails.location : '?'}}
      </span>, has 
      <span [ngClass]="{'missing-data': lifeCustomerDetails.familyMembers.length === 0, 'normal-data': lifeCustomerDetails.familyMembers.length > 0}">
        {{lifeCustomerDetails.familyMembers.length > 0 ? getCommaSeperatedString(getStringValues(lifeCustomerDetails.familyMembers)) : '?'}}
      </span>; Planning for 
      <span [ngClass]="{'missing-data': lifeCustomerDetails.lifeGoals.length === 0, 'normal-data': lifeCustomerDetails.lifeGoals.length > 0}">
        {{lifeCustomerDetails.lifeGoals.length > 0 ? getCommaSeperatedString(getStringValues(lifeCustomerDetails.lifeGoals)) : '?'}}
      </span>; Has annual income of 
      <span [ngClass]="{'missing-data': !lifeCustomerDetails.annualIncome, 'normal-data': lifeCustomerDetails.annualIncome}">
        {{lifeCustomerDetails.annualIncome ? lifeCustomerDetails.annualIncome : '?'}}
      </span> 
      <span *ngIf="lifeCustomerDetails.healthInsurance === 'yes'">
        and existing life insurance.
      </span>
    </p>
    
    
  </div>
  <div *ngIf="selectedProductType==='health'" class="bg-white p-2">
    <div class="flex items-center">
      <h2 class="font-bold">Customer profile</h2>
      <img *ngIf="!isEditing" (click)="startEditing(); toggleEditingMode()" class="h-2 w-2 ml-2 cursor-pointer" src="../../../../assets/icons/edit-pointers.png"/>
    </div>
    <p class="text-gray-700">
      <span [ngClass]="{'missing-data': !age, 'normal-data': age}">
        {{age > 0 ? age : '?'}}
      </span> years old, 
      <span [ngClass]="{'missing-data': !healthCustomerDetails.gender, 'normal-data': healthCustomerDetails.gender}">
        {{healthCustomerDetails.gender ? healthCustomerDetails.gender : '?'}}
      </span>, living in a 
      <span [ngClass]="{'missing-data': !healthCustomerDetails.location, 'normal-data': healthCustomerDetails.location}">
        {{healthCustomerDetails.location ? healthCustomerDetails.location : '?'}}
      </span>; Looking to cover 
      <span [ngClass]="{'missing-data': healthCustomerDetails.members[0].relationship==='', 'normal-data': healthCustomerDetails.members.length > 0}">
        {{healthCustomerDetails.members[0].relationship==='' ? '?' : getCommaSeperatedString(getSelectedMembers(healthCustomerDetails.members))}}
      </span> with 
      <span [ngClass]="{'missing-data': healthCustomerDetails.healthConditions.length === 0, 'normal-data': healthCustomerDetails.healthConditions.length > 0}">
        {{healthCustomerDetails.healthConditions.length > 0 ? getCommaSeperatedString(getStringValues(healthCustomerDetails.healthConditions)) : '?'}}
      </span>; Has 
      <span [ngClass]="{'missing-data': !getInsuranceString(), 'normal-data': getInsuranceString()}">
        {{getInsuranceString() ? getInsuranceString() : '?'}}
      </span> health insurance.
    </p>
    
  </div>

  <div class="bg-[#D9EAD5] navigation">
    <div class="flex justify-center">
      <div class="cursor-pointer px-4 py-2"
        [ngClass]="{ 'active-tab': activeTab === 'start', 'inactive-tab': activeTab !== 'start' }"
        (click)="setActiveTab('start'); onStartClicked()">
        Key points
      </div>
      <div class="cursor-pointer px-4 py-2"
        [ngClass]="{ 'active-tab': activeTab === 'other', 'inactive-tab': activeTab !== 'other' }"
        (click)="setActiveTab('other'); onOthersClicked()">
        Other points
      </div>
      <div class="cursor-pointer px-4 py-2"
        [ngClass]="{ 'active-tab': activeTab === 'dosdonts', 'inactive-tab': activeTab !== 'dosdonts' }"
        (click)="setActiveTab('dosdonts')">
        Dos and Don'ts
      </div>
    </div>
  </div>
  <div *ngIf="activeTab != 'dosdonts'">
    <div *ngIf="activeTab === 'start'" class="container mx-auto mt-2">
      <div *ngFor="let point of paginatedPoints[currentPage]"
        class="flex items-center border-b border-gray-300 mx-4 mb-4">
        <img class="h-6 w-6 mr-2" src="../../../../assets/icons/rupee.png"/>
        <p class="flex-1" [innerHTML]="point"></p>
      </div>
      <div *ngIf="pitchLoading" class="flex items-center justify-center mt-2">
        <div class="w-2 h-2 rounded-full animate-pulse loader mr-1"></div>
        <div class="w-2 h-2 rounded-full animate-pulse loader mr-1"></div>
        <div class="w-2 h-2 rounded-full animate-pulse loader"></div>
      </div>
    </div>
    <div *ngIf="activeTab === 'other'" class="container mx-auto mt-2">
      <div *ngFor="let point of paginatedPoints[currentPage]"
        class="flex items-center border-b border-gray-300 mx-4 mb-4">
        <img class="h-6 w-6 mr-2" src="../../../../assets/icons/rupee.png"/>
        <p class="flex-1" [innerHTML]="point"></p>
      </div>
    </div>
    <div *ngIf="!pitchLoading" class="flex justify-between items-center mx-4 mt-4">
      <!-- Pagination Buttons -->
      <div class="flex space-x-2">
        <button (click)="prevPage()" [disabled]="currentPage === 0"><</button>
        <ng-container *ngFor="let page of totalPages; let i = index">
          <button (click)="goToPage(i)" [ngClass]="{ 'font-bold': currentPage === i }">{{ i + 1 }}</button>
        </ng-container>
        <button (click)="nextPage()" [disabled]="currentPage === totalPages.length - 1">></button>
      </div>
      <!-- Like and Dislike Icons -->
      <div *ngIf="!pitchLoading" class="flex">
        <div [ngClass]="{'buttonClicked': pointersLiked}" (click)="responseLiked()"
          class="h-8 w-8 like rounded-full p-2">
          <img class="h-4 w-4 cursor-pointer" src="../../../../assets/icons/like.png" />
        </div>
        <div [ngClass]="{'buttonClicked': pointersDisliked}" (click)="this.showDialog()"
          class="h-8 w-8 dislike rounded-full p-2 ml-6">
          <img class="h-4 w-4 cursor-pointer" src="../../../../assets/icons/dislike.png" />
        </div>
      </div>
    </div>

  </div>
  <div *ngIf="activeTab === 'dosdonts'">
    <img class="h-52 w-52 mx-auto mt-6" src="../../../../assets/icons/coming-soon.png" />
  </div>

  <button routerLink="/vigi/product-queries" *ngIf="!pitchLoading" (click)="redirectToProductQueries()" class="question-button flex justify-center my-auto mx-auto mt-2">
    <img class="icon" src="../../../../assets/icons/question.png" />
    Ask a question
  </button>
  <div *ngIf="!pitchLoading" class="relative flex justify-center my-auto mx-auto compare-products mb-4">
    <button id="compare-button" [ngClass]="selectedProductToCompare===''?'bg-purple':'bg-green'" class="compare-button flex items-center justify-between">
      <img (click)="toggleCompareDropdown($event)" *ngIf="selectedProductToCompare===''" class="compare-icon" src="../../../../assets/icons/compare.png"/>
      <img (click)="toggleCompareDropdown($event)" *ngIf="selectedProductToCompare!''" class="compare-icon" src="../../../../assets/icons/compare-purple.png"/>
      <span class="w-full" (click)="toggleCompareDropdown($event)" *ngIf="selectedProductToCompare===''">Compare</span>
      <span (click)="toggleCompareDropdown($event)" *ngIf="selectedProductToCompare!''" class="font-normal font-purple">Compare with {{selectedCompanyToCompare+' - '+selectedProductToCompare}}</span>
      <img (click)="toggleCompareDropdown($event)" *ngIf="selectedProductToCompare===''" class="dropdown-icon" src="../../../../assets/icons/compare-dropdown.png"/>
      <button *ngIf="selectedProductToCompare!''" (click)="openComparePopup()" class="compare-arrow p-0 h-[30px] w-[30px] flex items-center justify-center">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-[24px] w-[24px]" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 12h14M12 5l7 7-7 7" />
        </svg>
      </button> 
    </button>
  
    <div *ngIf="isCompareDropdownOpen" class="compare-products-options absolute bottom-full">
      <ul>
        <li (click)="productClicked(option)" *ngFor="let option of compareProductsOptions" [ngClass]="{'bg-green': selectedProductToCompare === option.product}"><span>{{option.company+' - '+option.product}}</span></li>
      </ul>
    </div>
  
   
  </div>
  <p-dialog class="dislike-dialog" [(visible)]="display" [style]="{'background-color': '#D9EAD5', 'width': '290px', 'height': '250px'}">
    <div class="flex justify-between items-start">
      <div>
        <div class="reason-buttons">
          <label class="block font-medium mb-2">What went wrong?</label>
          <div class="flex reasons-checkbox">
            <div class="mr-3">
              <div class="flex items-center mb-1">
                <input type="checkbox" id="reason1" [(ngModel)]="reason1" (change)="getSelectedReasons()">
                <label for="reason1" class="ml-1">Not relevant</label>
              </div>
              <div class="flex items-center mb-1">
                <input type="checkbox" id="reason2" [(ngModel)]="reason2" (change)="getSelectedReasons()">
                <label for="reason2" class="ml-1">Not accurate</label>
              </div>
              <div class="flex items-center mb-1">
                <input type="checkbox" id="reason3" [(ngModel)]="reason3" (change)="getSelectedReasons()">
                <label for="reason3" class="ml-1">Repetitive</label>
              </div>
            </div>
            <div>
              <div class="flex items-center mb-1">
                <input type="checkbox" id="reason4" [(ngModel)]="reason4" (change)="getSelectedReasons()">
                <label for="reason4" class="ml-1">Can't understand</label>
              </div>
              <div class="flex items-center mb-1">
                <input type="checkbox" id="others" [(ngModel)]="others" (change)="getSelectedReasons()">
                <label for="others" class="ml-1">Others</label>
              </div>
            </div>
          </div>
        </div>
        <label class="block font-medium mt-1 ml-1">Please help us understand better</label>
        <textarea class="text-xs pl-1 pt-1" [(ngModel)]="feedback"></textarea>
      </div>
      <div class="ml-auto">
        <button class="p-link" (click)="display=false">
          <i class="pi pi-times"></i>
        </button>
      </div>
    </div>
    <button type="button" (click)="responseDisLiked()"
      class="custom-button flex justify-center my-auto mx-auto">Submit</button>
  </p-dialog>

</div>
<div  *ngIf="isSkipped">
  <header>
    <div class="container mx-auto flex items-center justify-between">
      <!-- Logo and Name -->
      <div class="flex items-center">
        <button (click)="goBack()" class="focus:outline-none">
          <svg class="h-8 w-8 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 12H5M12 19l-7-7 7-7"></path>
          </svg>
        </button>
        <span class="text-xl ml-3 font-bold">Sales pitch pointers</span>
      </div>
      <!-- Kebab Icon -->
      <div class="flex items-center">
        <button (onblur)="toggleDropdown()" (click)="toggleDropdown()" class="focus:outline-none toggle-button">
          <img class="h-6 w-6" src="../../../../assets/icons/menu.png" />
        </button>
        <div [ngClass]="{'dropdown-open': isDropdownOpen}" class="header-wrapper absolute bg-white text-black rounded-md shadow-lg overflow-hidden">
          <div class="navigation-options">
            <span routerLink="/home" class="block px-4 py-2 text-sm">Exit</span>
            <span routerLink="/vigi" class="block px-4 py-2 text-sm">Change product</span>
          </div>
        </div>
      </div>
    </div>
  </header>
  <div class="title-section h-10 flex items-center">
    <span class="mx-auto">{{selectedProduct.company+' - '+selectedProduct.product}}</span>
  </div>
  <div class="relative profile-container border p-2 border-gray-300">
    <div class="flex items-center">
      <h2 class="font-bold">Customer profile</h2>
      <img *ngIf="!isEditing" (click)="startEditing()" class="h-2 w-2 ml-2 cursor-pointer" src="../../../../assets/icons/edit-pointers.png"/>
    </div>
  
    <textarea id="input" [value]="inputValue" [readonly]="!isEditing" *ngIf="isEditing || profileFilled" [(ngModel)]="inputValue" 
              class="w-full mt-2 bg-transparent border-none outline-none resize-none overflow-hidden"
              (input)="adjustTextareaHeight($event)">
    </textarea>
  
    <button *ngIf="isEditing" (click)="send()" class="absolute bottom-2 right-2 text-blue-500 p-0 h-[30px] w-[30px] flex items-center justify-center">
      <svg xmlns="http://www.w3.org/2000/svg" class="h-[24px] w-[24px]" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 12h14M12 5l7 7-7 7" />
      </svg>
    </button> 
  </div>
  <div *ngIf="profileFilled" class="bg-[#D9EAD5] navigation">
    <div class="flex justify-center">
      <div class="cursor-pointer px-4 py-2"
        [ngClass]="{ 'active-tab': activeTab === 'start', 'inactive-tab': activeTab !== 'start' }"
        (click)="setActiveTab('start'); onStartClicked()">
        Key points
      </div>
      <div class="cursor-pointer px-4 py-2"
        [ngClass]="{ 'active-tab': activeTab === 'other', 'inactive-tab': activeTab !== 'other' }"
        (click)="setActiveTab('other'); onOthersClicked()">
        Other points
      </div>
      <div class="cursor-pointer px-4 py-2"
        [ngClass]="{ 'active-tab': activeTab === 'dosdonts', 'inactive-tab': activeTab !== 'dosdonts' }"
        (click)="setActiveTab('dosdonts')">
        Dos and Don'ts
      </div>
    </div>
  </div>
  <div *ngIf="activeTab != 'dosdonts'">
    <div *ngIf="activeTab === 'start'" class="container mx-auto mt-2">
      <div *ngFor="let point of paginatedPoints[currentPage]"
        class="flex items-center border-b border-gray-300 mx-4 mb-4">
        <img class="h-6 w-6 mr-2" src="../../../../assets/icons/rupee.png"/>
        <p class="flex-1" [innerHTML]="point"></p>
      </div>
      <div *ngIf="pitchLoading && profileFilled" class="flex items-center justify-center mt-2">
        <div class="w-2 h-2 rounded-full animate-pulse loader mr-1"></div>
        <div class="w-2 h-2 rounded-full animate-pulse loader mr-1"></div>
        <div class="w-2 h-2 rounded-full animate-pulse loader"></div>
      </div>
    </div>
    <div *ngIf="activeTab === 'other'" class="container mx-auto mt-2">
      <div *ngFor="let point of paginatedPoints[currentPage]"
        class="flex items-center border-b border-gray-300 mx-4 mb-4">
        <img class="h-6 w-6 mr-2" src="../../../../assets/icons/rupee.png"/>
        <p class="flex-1" [innerHTML]="point"></p>
      </div>
    </div>
    <div *ngIf="!pitchLoading && profileFilled" class="flex justify-between items-center mx-4 mt-4">
      <!-- Pagination Buttons -->
      <div class="flex space-x-2">
        <button (click)="prevPage()" [disabled]="currentPage === 0"><</button>
        <ng-container *ngFor="let page of totalPages; let i = index">
          <button (click)="goToPage(i)" [ngClass]="{ 'font-bold': currentPage === i }">{{ i + 1 }}</button>
        </ng-container>
        <button (click)="nextPage()" [disabled]="currentPage === totalPages.length - 1">></button>
      </div>
      <!-- Like and Dislike Icons -->
      <div *ngIf="!pitchLoading && profileFilled" class="flex">
        <div [ngClass]="{'buttonClicked': pointersLiked}" (click)="responseLiked()"
          class="h-8 w-8 like rounded-full p-2">
          <img class="h-4 w-4 cursor-pointer" src="../../../../assets/icons/like.png" />
        </div>
        <div [ngClass]="{'buttonClicked': pointersDisliked}" (click)="this.showDialog()"
          class="h-8 w-8 dislike rounded-full p-2 ml-6">
          <img class="h-4 w-4 cursor-pointer" src="../../../../assets/icons/dislike.png" />
        </div>
      </div>
    </div>

  </div>
  <div *ngIf="activeTab === 'dosdonts'">
    <img class="h-52 w-52 mx-auto mt-6" src="../../../../assets/icons/coming-soon.png" />
  </div>
  <button *ngIf="!pitchLoading && profileFilled" routerLink="/vigi/product-queries"  (click)="redirectToProductQueries()" class="question-button flex justify-center my-auto mx-auto mt-2">
    <img class="icon" src="../../../../assets/icons/question.png" />
    Ask a question
  </button>
  <div *ngIf="!pitchLoading && profileFilled" class="relative flex justify-center my-auto mx-auto compare-products mb-4">
    <button id="compare-button" [ngClass]="selectedProductToCompare===''?'bg-purple':'bg-green'" class="compare-button flex items-center justify-between">
      <img (click)="toggleCompareDropdown($event)" *ngIf="selectedProductToCompare===''" class="compare-icon" src="../../../../assets/icons/compare.png"/>
      <img (click)="toggleCompareDropdown($event)" *ngIf="selectedProductToCompare!''" class="compare-icon" src="../../../../assets/icons/compare-purple.png"/>
      <span class="w-full" (click)="toggleCompareDropdown($event)" *ngIf="selectedProductToCompare===''">Compare</span>
      <span (click)="toggleCompareDropdown($event)" *ngIf="selectedProductToCompare!''" class="font-normal font-purple">Compare with {{selectedCompanyToCompare+' - '+selectedProductToCompare}}</span>
      <img (click)="toggleCompareDropdown($event)" *ngIf="selectedProductToCompare===''" class="dropdown-icon" src="../../../../assets/icons/compare-dropdown.png"/>
      <button *ngIf="selectedProductToCompare!''" (click)="openComparePopup()" class="compare-arrow p-0 h-[30px] w-[30px] flex items-center justify-center">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-[24px] w-[24px]" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 12h14M12 5l7 7-7 7" />
        </svg>
      </button> 
    </button>
  
    <div *ngIf="isCompareDropdownOpen" class="compare-products-options absolute bottom-full">
      <ul>
        <li (click)="productClicked(option)" *ngFor="let option of compareProductsOptions" [ngClass]="{'bg-green': selectedProductToCompare === option.product}"><span>{{option.company+' - '+option.product}}</span></li>
      </ul>
    </div>
  
   
  </div>
  
  <p-dialog class="dislike-dialog" [(visible)]="display" [style]="{'background-color': '#D9EAD5', 'width': '290px', 'height': '250px'}">
    <div class="flex justify-between items-start">
      <div>
        <div class="reason-buttons">
          <label class="block font-medium mb-2">What went wrong?</label>
          <div class="flex">
            <div class="mr-3">
              <div class="flex items-center mb-1">
                <input type="checkbox" id="reason1" [(ngModel)]="reason1" (change)="getSelectedReasons()">
                <label for="reason1" class="ml-1">Not relevant</label>
              </div>
              <div class="flex items-center mb-1">
                <input type="checkbox" id="reason2" [(ngModel)]="reason2" (change)="getSelectedReasons()">
                <label for="reason2" class="ml-1">Not accurate</label>
              </div>
              <div class="flex items-center mb-1">
                <input type="checkbox" id="reason3" [(ngModel)]="reason3" (change)="getSelectedReasons()">
                <label for="reason3" class="ml-1">Repetitive</label>
              </div>
            </div>
            <div>
              <div class="flex items-center mb-1">
                <input type="checkbox" id="reason4" [(ngModel)]="reason4" (change)="getSelectedReasons()">
                <label for="reason4" class="ml-1">Can't understand</label>
              </div>
              <div class="flex items-center mb-1">
                <input type="checkbox" id="others" [(ngModel)]="others" (change)="getSelectedReasons()">
                <label for="others" class="ml-1">Others</label>
              </div>
            </div>
          </div>
        </div>
        <label class="block font-medium mt-1 ml-1">Please help us understand better</label>
        <textarea class="text-xs pl-1 pt-1" [(ngModel)]="feedback"></textarea>
      </div>
      <div class="ml-auto">
        <button class="p-link" (click)="display=false">
          <i class="pi pi-times"></i>
        </button>
      </div>
    </div>
    <button type="button" (click)="responseDisLiked()"
      class="custom-button flex justify-center my-auto mx-auto">Submit</button>
  </p-dialog>
</div>
<p-dialog class="compare-products-dialog" [(visible)]="isComparePopupOpen" [style]="{'background-color': '#D9EAD5', 'width': '85vw', 'height': '85vh', 'border-radius': '5px'}">
  <p-header>
    <p class="font-normal text-xs"><span class="italic">“{{selectedProduct.company+' - '+selectedProduct.product}}”</span> is better for this customer than <span class="italic">“{{selectedCompanyToCompare+' - '+selectedProductToCompare}}”</span>, because:</p>
  </p-header>
  <div>
    <div *ngIf="isComparisionPointsLoading" class="flex items-center justify-center mt-14">
      <img class="w-2/3 h-2/3" src="../../../../assets/icons/loader.gif"/>
    </div>
    <p *ngIf="isComparisionPointsLoading" class="text-center italic mt-3 mr-3">Loading...</p>
    <div *ngFor="let point of comparisionPoints"
        class="flex items-center border-b border-gray-300 mb-2 mt=-2">
        <p class="flex-1 text-xs" [innerHTML]="point"></p>
      </div>
  </div>
</p-dialog>
<div *ngIf="isCompareDropdownOpen || isComparePopupOpen" class="scrim"></div>