import { Component, ElementRef, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { CommonService } from 'src/app/services/common.service';
import { GoogleApiService } from '../../../services/google-api.service';



@Component({
  selector: 'app-sales-pitch',
  templateUrl: './sales-pitch.component.html',
  styleUrls: ['./sales-pitch.component.css']
})
export class SalesPitchComponent {

  translatedText: string = ''; // Translated text
  isListening: boolean = false; // Tracks if listening
  voiceError: string = ''; // Error messages for voice
  recognition: any; // SpeechRecognition instance
  processedResults: Set<string> = new Set();
  debounceTimeout: any = null;
  combinedTranscript: string = '';
  activeTab: string = 'start';
  selectedProduct: any;
  selectedProductType: string = "";
  showSalesPitchModule: boolean = false;
  lifeCustomerDetails: any;
  healthCustomerDetails: any;
  display: boolean = false;
  reason1: boolean = false;
  reason2: boolean = false;
  reason3: boolean = false;
  reason4: boolean = false;
  others: boolean = false;
  selectedReasonsArray: string[] = [];
  feedback: string = '';
  pointersDisliked: boolean = false;
  pointersLiked: boolean = false;
  pointsResponse: any = null;
  points = [];
  otherPoints = []
  paginatedPoints = [];
  currentPage = 0;
  pointsPerPage = 6;
  totalPages = [];
  pitchLoading: boolean = true;
  age: any = 0;
  isSkipped: boolean = false;
  isEditing = false;
  profileFilled = false;
  inputValue = '';
  compareProductsOptions: any = [];
  selectedProductToCompare: string = "";
  selectedCompanyToCompare: string = "";
  isComparePopupOpen: boolean = false;
  isComparisionPointsLoading: boolean = false;
  comparisionPoints: any = [];
  generatedPayloadForSalesPitch: any;
  pageName: String = "Sales Pitch";
  history: any = [];
  $subscription: Subscription;
  $historySubscription: Subscription;
  $salesPitchPointersSubscription: Subscription;
  lastRoute: any;
  isPointersAlreadyGenerated: boolean = false;
  isOnLoad: boolean = true;
  pointersIcons: any = {};
  isVoiceEnabled: boolean = false;
  $voiceSubscription: Subscription;

  constructor(public commonService: CommonService, private apiService: ApiService,
    private eRef: ElementRef, private router: Router, private googleApi: GoogleApiService) {
    commonService.productSelection.subscribe((product) => {
      this.selectedProduct = product;
      if(product==="")
        router.navigate(["/home"]);
    })
    this.selectedProductType = this.selectedProduct.productType;
    if (this.selectedProductType === 'life') {
      commonService.lifeProducts.subscribe((products) => {
        this.compareProductsOptions = products.filter(x => x.product != this.selectedProduct.product)
      })
    }
    else {
      commonService.healthProducts.subscribe((products) => {
        this.compareProductsOptions = products.filter(x => x.product != this.selectedProduct.product)
      })
    }
    this.$historySubscription = commonService.history.subscribe((res) => {
      this.history = res;
      this.lastRoute = this.history[this.history.length - 1];
    })
    this.$salesPitchPointersSubscription = commonService.salesPointers.subscribe((res: any) => {
      if (res != null && !(Array.isArray(res))) {
        this.pointsResponse = res;
        this.isPointersAlreadyGenerated = true;
        this.formatSalesPointers(res);
        this.populatePointersIcons(res.benefit_icons);
        this.pitchLoading = false;
        this.profileFilled = true
        this.isEditing = false;
      }
      else
        this.isPointersAlreadyGenerated = false;
    })
    this.$voiceSubscription = commonService.isVoiceModeEnabled.subscribe((res) => {
      this.isVoiceEnabled = res;
    })
  }

  setActiveTab(tab: string): void {
    this.activeTab = tab;
  }

  customerDetailsForHealth(event: any) {
    if (!event.isSkipped) {
      this.resetSessionId();
      this.isSkipped = false;
      this.showSalesPitchModule = true;
      this.healthCustomerDetails = event;
      let self = this.healthCustomerDetails.members.filter(m => m.relationship === 'self')
      if (self.length > 0) {
        this.age = self[0].age;
      }
      let dependants = "";
      if (this.healthCustomerDetails.members.length > 0) {
        dependants = this.getCommaSeperatedString(this.getSelectedMembers(this.healthCustomerDetails.members));
      }
      let diseases = "";
      if (this.healthCustomerDetails.healthConditions.length > 0) {
        const diseases = this.getCommaSeperatedString(this.getStringValues(this.healthCustomerDetails.healthConditions));
      }
      const existingInsurance = this.getInsuranceString();
      let payLoad = {
        gender: this.healthCustomerDetails.gender,
        dependants: dependants,
        diseases: diseases,
        existing_insurance: existingInsurance,
        location: this.selectedProduct.location,
        session_id: sessionStorage.getItem('sessionId'),
        ...(this.age != 0 && { age: this.age })
      }
      this.generatedPayloadForSalesPitch = payLoad;
      this.isOnLoad = false;
      if (this.pointsResponse === null) {
        let custProfile = {
          string: null,
          form: this.healthCustomerDetails,
          from: 'salespitch'
        }
        this.commonService.setCustomerProfile(custProfile);
        this.apiService.getSalesPitchForHealth(payLoad, this.selectedProduct.company, this.selectedProduct.product).subscribe({
          next: (res) => {
            this.pointsResponse = res;
            this.commonService.salesPointers.next(res);
            this.pitchLoading = false;
            this.formatSalesPointers(res);
            this.populatePointersIcons(res.benefit_icons);
          },
          error: (err) => {

          }
        })
      }
    }
    else {
      this.isSkipped = true;
      this.startEditing();
    }
  }

  customerDetailsForLife(event: any) {
    if (!event.isSkipped) {
      localStorage.setItem("customerProfileForm", event);
      this.resetSessionId();
      this.isSkipped = false;
      this.showSalesPitchModule = true;
      this.lifeCustomerDetails = event;
      let familyMembers = "";
      if (this.lifeCustomerDetails?.familyMembers.length > 0) {
        familyMembers = this.getCommaSeperatedString(this.getStringValues(this.lifeCustomerDetails?.familyMembers));
      }
      let goals = "";
      if (this.lifeCustomerDetails?.lifeGoals.length > 0) {
        goals = this.getCommaSeperatedString(this.getStringValues(this.lifeCustomerDetails?.lifeGoals))
      }
      let payload = {
        age: this.lifeCustomerDetails.age,
        gender: this.lifeCustomerDetails.gender,
        occupation: this.lifeCustomerDetails.occupation,
        family_members: familyMembers,
        expected_retirement_age: this.lifeCustomerDetails.retirementAge,
        annual_income: this.lifeCustomerDetails.annualIncome,
        annual_expenditure: this.lifeCustomerDetails.annualExpenditure,
        investments: this.lifeCustomerDetails.investments,
        outstanding_loans: this.lifeCustomerDetails.outstandingLoans,
        life_goals: goals,
        amount_needed: this.lifeCustomerDetails.amountNeeded,
        existing_insurance: this.lifeCustomerDetails.healthInsurance,
        coverage: this.lifeCustomerDetails.lifeInsuranceCoverage,
        location: this.lifeCustomerDetails.location,
        session_id: sessionStorage.getItem('sessionId')
      }
      this.generatedPayloadForSalesPitch = payload;
      this.isOnLoad = false;
      if (this.pointsResponse === null) {
        let custProfile = {
          string: null,
          form: event,
          from: 'salespitch'
        }
        this.commonService.setCustomerProfile(custProfile);
        this.apiService.getSalesPitchForLife(payload, this.selectedProduct.company, this.selectedProduct.product).subscribe({
          next: (res) => {
            this.pointsResponse = res;
            this.pitchLoading = false;
            this.commonService.salesPointers.next(res);
            this.formatSalesPointers(res);
            this.populatePointersIcons(res.benefit_icons);
          },
          error: (err) => {

          }
        })
      }
    }
    else {
      this.isSkipped = true;
      this.startEditing();
    }
  }

  onSubmit() {
  }

  redirectToProductQueries() {
    this.updateHistory();
  }

  showDialog() {
    if (!this.pointersLiked && !this.pointersDisliked) {
      this.display = true;
    }
  }

  getStringValues(items) {
    return items.map(item => item.item_text);
  }

  getCommaSeperatedString(values): string {
    if (values.length === 0) {
      return '';
    }
    if (values.length === 1) {
      return values[0];
    }
    if (values.length === 2) {
      return values.join(' and ');
    }
    const lastGoal = values.pop();
    return values.join(', ') + ', and ' + lastGoal;
  }

  getSelectedMembers(object: any) {
    return object.map(member => member.relationship);
  }

  getInsuranceString(): string {
    if (this.healthCustomerDetails.insuranceNone) {
      return 'no';
    }
    if (this.healthCustomerDetails.insuranceGroup && this.healthCustomerDetails.insuranceRetail) {
      return 'Group and Individual';
    }
    if (this.healthCustomerDetails.insuranceGroup) {
      return 'group';
    }
    if (this.healthCustomerDetails.insuranceRetail) {
      return 'individual';
    }
    return 'Insurance status unknown';
  }

  isDropdownOpen = false;

  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  redirectToCustomerDetails() {
    this.showSalesPitchModule = false;
    this.profileFilled = false;
    this.isSkipped = false;
    this.paginatedPoints = [];
    this.inputValue = '';
  }

  paginatePoints() {
    this.paginatedPoints = [];
    for (let i = 0; i < this.points.length; i += this.pointsPerPage) {
      this.paginatedPoints.push(this.points.slice(i, i + this.pointsPerPage));
    }
  }

  paginateOtherPoints() {
    this.paginatedPoints = [];
    for (let i = 0; i < this.otherPoints.length; i += this.pointsPerPage) {
      this.paginatedPoints.push(this.otherPoints.slice(i, i + this.pointsPerPage));
    }
  }

  calculateTotalPages() {
    this.totalPages = Array(Math.ceil(this.points.length / this.pointsPerPage)).fill(0).map((x, i) => i);
    this.currentPage = 0;
  }

  calculateTotalPagesForOtherPoints() {
    this.totalPages = Array(Math.ceil(this.otherPoints.length / this.pointsPerPage)).fill(0).map((x, i) => i);
    this.currentPage = 0;
  }

  nextPage() {
    if (this.currentPage < this.paginatedPoints.length - 1) {
      this.currentPage++;
    }
  }

  prevPage() {
    if (this.currentPage > 0) {
      this.currentPage--;
    }
  }

  goToPage(page: number) {
    this.currentPage = page;
  }

  onOthersClicked() {
    this.paginateOtherPoints();
    this.calculateTotalPagesForOtherPoints();
  }

  onStartClicked() {
    this.paginatePoints();
    this.calculateTotalPages();
  }

  startEditing() {
    this.isEditing = true;
    setTimeout(() => {
      const inputElement = document.querySelector('textarea') as HTMLTextAreaElement;
      if (inputElement) {
        inputElement.focus();
      }
    }, 0);
  }

  send(isProfileEdited?: boolean) {
    localStorage.setItem("customerProfileDetails", this.inputValue);
    this.resetSessionId();
    this.profileFilled = true
    this.isEditing = false;
    this.selectedProductToCompare = "";
    this.selectedCompanyToCompare = "";
    this.pointersLiked = false;
    this.pointersDisliked = false;
    this.isSkipped = true;
    const customer_profile = this.inputValue;
    this.isOnLoad = false;
    if (isProfileEdited) {
      this.pitchLoading = true;
      this.paginatedPoints = [];
    }
    if (this.selectedProductType === 'life' && (this.pointsResponse === null || isProfileEdited)) {
      let custProfile = {
        string: this.inputValue,
        form: null,
        from: 'salespitch'
      }
      this.commonService.setCustomerProfile(custProfile);
      this.apiService.getSalesPitchForLifeWithProfie(customer_profile, this.selectedProduct.company, this.selectedProduct.product).subscribe({
        next: (res) => {
          this.paginatedPoints = [];
          this.pointsResponse = res;
          this.commonService.salesPointers.next(res);
          this.formatSalesPointers(res);
          this.populatePointersIcons(res.benefit_icons);
          this.pitchLoading = false;
        },
        error: (err) => {

        }
      })
    }
    else {
      if (this.pointsResponse === null || isProfileEdited) {
        let custProfile = {
          string: this.inputValue,
          form: null,
          from: 'salespitch'
        }
        this.commonService.setCustomerProfile(custProfile);
        this.apiService.getSalesPitchForHealthWithProfile(customer_profile, this.selectedProduct.company, this.selectedProduct.product).subscribe({
          next: (res) => {
            this.pointsResponse = res;
            this.commonService.salesPointers.next(res);
            this.formatSalesPointers(res);
          },
          error: (err) => {

          }
        })
      }
    }
  }

  adjustTextareaHeight(event: Event): void {
    const textarea = event.target as HTMLTextAreaElement;
    textarea.style.height = 'auto';  // Reset the height to auto
    textarea.style.height = textarea.scrollHeight + 'px';  // Set it to the scroll height

    // Ensure the outer container grows with the textarea
    const container = textarea.closest('.profile-container') as HTMLElement;
    if (container) {
      container.style.height = 'auto';  // Reset the container height to auto
      container.style.height = container.scrollHeight + 4 + 'px';  // Set it to the scroll height
    }
  }


  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const target = event.target as HTMLElement;
    const clickedInside = target.closest('.navigation-options') || target.closest('.toggle-button');

    if (!clickedInside && this.isDropdownOpen || this.isCompareDropdownOpen) {
      this.isDropdownOpen = false;
      this.isCompareDropdownOpen = false;
      this.changeButtonVisibility(false);
    }
  }

  isCompareDropdownOpen = false;

  toggleCompareDropdown(event: Event) {
    event.stopPropagation();
    if (this.isCompareDropdownOpen)
      this.changeButtonVisibility(false);
    else
      this.changeButtonVisibility(true);
    this.isCompareDropdownOpen = !this.isCompareDropdownOpen;
  }

  productClicked(product: any) {
    this.selectedProductToCompare = product.product;
    this.selectedCompanyToCompare = product.company;
    this.isCompareDropdownOpen = false;
    this.changeButtonVisibility(false);
  }

  openComparePopup() {
    this.isComparePopupOpen = true;
    this.comparisionPoints = [];
    const customer_profile = this.inputValue;
    this.isComparisionPointsLoading = true;
    if (this.isSkipped) {
      this.apiService.getComparisonSummaryWithProfile(customer_profile, this.selectedProduct.company, this.selectedProduct.product
        , this.selectedProductType, this.selectedProductToCompare, this.selectedCompanyToCompare).subscribe({
          next: (res) => {
            const parser = new DOMParser();
            let doc = parser.parseFromString(res.comparison, 'text/html');
            let points = Array.from(doc.querySelectorAll('p + ol:nth-of-type(1) li')).map(li => li.outerHTML);

            if (points.length > 0) {
              this.comparisionPoints = points;
            } else {
              this.comparisionPoints = [res.comparison];
            }
            this.isComparisionPointsLoading = false;
          },
          error: (err) => {

          }
        })
    }
    else {
      this.generatedPayloadForSalesPitch = {
        ...this.generatedPayloadForSalesPitch,
        product2: this.selectedProductToCompare,
        company2: this.selectedCompanyToCompare
      };
      this.apiService.getComparisonSummaryWithFormDetails(this.generatedPayloadForSalesPitch, this.selectedProduct.company, this.selectedProduct.product
        , this.selectedProductType, this.selectedProductToCompare, this.selectedCompanyToCompare
      ).subscribe({
        next: (res) => {
          const parser = new DOMParser();
          let doc = parser.parseFromString(res.comparison, 'text/html');
          let points = Array.from(doc.querySelectorAll('p + ol:nth-of-type(1) li')).map(li => li.outerHTML);

          if (points.length > 0) {
            this.comparisionPoints = points;
          } else {
            this.comparisionPoints = [res.comparison];
          }
          this.isComparisionPointsLoading = false;
        },
        error: (res) => {

        }
      })
    }
  }

  changeButtonVisibility(flag: boolean) {
    let element = document.getElementById("compare-button") as HTMLElement;
    if (flag)
      element.style.zIndex = '10';
    else
      element.style.zIndex = '0';
  }

  toggleEditingMode() {
    this.showSalesPitchModule = false;
    this.isSkipped = true;
    this.profileFilled = false;
    this.paginatedPoints = [];
    this.otherPoints = [];
    this.selectedProductToCompare = "";
    this.selectedCompanyToCompare = "";
  }

  resetSessionId() {
    sessionStorage.removeItem('sessionId'); // Remove the current session ID
    const newSessionId = this.generateRandomString(16); // Generate a new random session ID
    sessionStorage.setItem('sessionId', newSessionId); // Set the new session ID
  }

  generateRandomString(length) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  getSelectedReasons() {
    // Handle 'Not relevant'
    if (this.reason1 && !this.selectedReasonsArray.includes('Not relevant')) {
      this.selectedReasonsArray.push('Not relevant');
    } else if (!this.reason1) {
      this.selectedReasonsArray = this.selectedReasonsArray.filter(reason => reason !== 'Not relevant');
    }

    // Handle 'Not accurate'
    if (this.reason2 && !this.selectedReasonsArray.includes('Not accurate')) {
      this.selectedReasonsArray.push('Not accurate');
    } else if (!this.reason2) {
      this.selectedReasonsArray = this.selectedReasonsArray.filter(reason => reason !== 'Not accurate');
    }

    // Handle 'Repetitive'
    if (this.reason3 && !this.selectedReasonsArray.includes('Repetitive')) {
      this.selectedReasonsArray.push('Repetitive');
    } else if (!this.reason3) {
      this.selectedReasonsArray = this.selectedReasonsArray.filter(reason => reason !== 'Repetitive');
    }

    // Handle "Can't understand"
    if (this.reason4 && !this.selectedReasonsArray.includes("Can't understand")) {
      this.selectedReasonsArray.push("Can't understand");
    } else if (!this.reason4) {
      this.selectedReasonsArray = this.selectedReasonsArray.filter(reason => reason !== "Can't understand");
    }

    // Handle 'Others'
    if (this.others && !this.selectedReasonsArray.includes('Others')) {
      this.selectedReasonsArray.push('Others');
    } else if (!this.others) {
      this.selectedReasonsArray = this.selectedReasonsArray.filter(reason => reason !== 'Others');
    }

    // Join the selected reasons into a single string
    const selectedReasons = this.selectedReasonsArray.join(', ');

    return selectedReasons;
  }

  getCustomerSummaryForLife(lifeCustomerDetails: any): string {

    // Build the summary string
    const age = lifeCustomerDetails.age ? lifeCustomerDetails.age : '?';
    const gender = lifeCustomerDetails.gender ? lifeCustomerDetails.gender : '?';
    const location = lifeCustomerDetails.location ? lifeCustomerDetails.location : '?';
    const familyMembers = lifeCustomerDetails.familyMembers.length > 0
      ? this.getCommaSeperatedString(this.getStringValues(lifeCustomerDetails.familyMembers))
      : '?';
    const lifeGoals = lifeCustomerDetails.lifeGoals.length > 0
      ? this.getCommaSeperatedString(this.getStringValues(lifeCustomerDetails.lifeGoals))
      : '?';
    const annualIncome = lifeCustomerDetails.annualIncome ? lifeCustomerDetails.annualIncome : '?';
    const healthInsurance = lifeCustomerDetails.healthInsurance === 'yes' ? ' and existing life insurance.' : '';

    // Construct the summary
    const summary = `${age} years old, ${gender}, living in a ${location}, has ${familyMembers}; 
    Planning for ${lifeGoals}; Has annual income of ${annualIncome}${healthInsurance}`;

    return summary;
  }

  getCustomerSummaryForHealth(age: number, healthCustomerDetails: any): string {
    // Build the summary string
    const ageString = age > 0 ? age : '?';
    const gender = healthCustomerDetails.gender ? healthCustomerDetails.gender : '?';
    const location = healthCustomerDetails.location ? healthCustomerDetails.location : '?';
    const selectedMembers = healthCustomerDetails.members.length > 0 && healthCustomerDetails.members[0].relationship !== ''
      ? this.getCommaSeperatedString(this.getSelectedMembers(healthCustomerDetails.members))
      : '?';
    const healthConditions = healthCustomerDetails.healthConditions.length > 0
      ? this.getCommaSeperatedString(this.getStringValues(healthCustomerDetails.healthConditions))
      : '?';
    const insuranceString = this.getInsuranceString() ? this.getInsuranceString() : '?';

    // Construct the summary
    const healthSummary = `${ageString} years old, ${gender}, living in a ${location}; Looking to cover ${selectedMembers} with ${healthConditions}; Has ${insuranceString} health insurance.`;

    return healthSummary;
  }



  responseLiked() {
    let userDetails = "";
    if (!this.pointersLiked && !this.pointersDisliked) {
      this.isSkipped ? userDetails = this.inputValue : this.selectedProduct.productType === 'life' ? userDetails = this.getCustomerSummaryForLife(this.lifeCustomerDetails) : userDetails = this.getCustomerSummaryForHealth(this.age, this.healthCustomerDetails)
      let payLoad = {
        session_id: sessionStorage.getItem("sessionId"),
        session_type: "pitch",
        category: this.selectedProduct.productType,
        company: this.selectedProduct.company,
        product: this.selectedProduct.product,
        user_input: userDetails,
        model_response: this.pointsResponse.pitch_html,
        feedback_type: "positive",
        feedback_category: "Accurate"
      }
      this.apiService.addFeedback(payLoad).subscribe({
        next: (res: any) => {
          this.pointersLiked = true;
        },
        error: (err: any) => {
        }
      })
    }
  }

  responseDisLiked() {
    let userDetails = "";
    if (!this.pointersLiked && !this.pointersDisliked) {
      this.isSkipped ? userDetails = this.inputValue : this.selectedProduct.productType === 'life' ? userDetails = this.getCustomerSummaryForLife(this.lifeCustomerDetails) : userDetails = this.getCustomerSummaryForHealth(this.age, this.healthCustomerDetails)
      let payLoad = {
        session_id: sessionStorage.getItem("sessionId"),
        session_type: "pitch",
        category: this.selectedProduct.productType,
        company: this.selectedProduct.company,
        product: this.selectedProduct.product,
        user_input: userDetails,
        model_response: this.pointsResponse.pitch_html,
        feedback_type: "negative",
        feedback_category: this.getSelectedReasons(),
        feedback_text: this.feedback
      }
      this.apiService.addFeedback(payLoad).subscribe({
        next: (res: any) => {
          this.pointersDisliked = true;
          this.display = false;
          this.reason1 = false;
          this.reason2 = false;
          this.reason3 = false;
          this.reason4 = false;
          this.others = false;
          this.feedback = "";
        },
        error: (err: any) => {
        }
      })
    }
  }

  formatSalesPointers(res) {
    const parser = new DOMParser();
    let doc = parser.parseFromString(res.pitch_html, 'text/html');
    const highlyEffectiveItems = doc.querySelectorAll('p + ol:nth-of-type(1) li');
    const othersItems = doc.querySelectorAll('p + ol:nth-of-type(2) li');
    const highlyEffective = Array.from(highlyEffectiveItems).map(li => li.innerHTML.trim());
    const others = Array.from(othersItems).map(li => li.innerHTML.trim());
    this.points = highlyEffective;
    this.otherPoints = others;
    this.paginatePoints();
    this.calculateTotalPages();
  }

  populatePointersIcons(benefit_icons: any) {
    this.pointersIcons = {
      highlyEffective: benefit_icons['highly effective'],
      other: benefit_icons.other
    }
    console.log(this.pointersIcons);
  }

  goBack() {
    if (this.lastRoute === 'vigi/sales-pitch') {
      this.history.pop();
      this.commonService.history.next(this.history);
      this.router.navigate([this.history[this.history.length - 1]]);
    }
    else
      this.router.navigate([this.lastRoute]);
  }

  ngOnDestroy() {
    this.$subscription.unsubscribe();
    this.$historySubscription.unsubscribe();
    this.$salesPitchPointersSubscription.unsubscribe();
    this.$voiceSubscription.unsubscribe();
  }

  ngOnInit() {
    this.$subscription = this.commonService.customerProfile.subscribe((profile) => {
      if ((profile.string != null || profile.form != null) && this.isOnLoad) {
        this.isOnLoad = false;
        if (profile.string != null) {
          this.inputValue = profile.string;
          this.send();
        }
        else {
          if (this.selectedProductType === 'life')
            this.customerDetailsForLife(profile.form);
          else
            this.customerDetailsForHealth(profile.form)
        }
      }
    })
  }

  updateHistory() {
    this.history.push("vigi/sales-pitch");
    this.commonService.setHistory(this.history);
  }

  // Toggle voice recognition
  toggleVoiceRecognition() {
    if (this.isListening) {
      this.stopVoiceRecognition();
    } else {
      this.startVoiceRecognition();
    }
  }

  // Start voice recognition
  startVoiceRecognition() {
    const SpeechRecognition =
      (window as any).SpeechRecognition || (window as any).webkitSpeechRecognition;

    if (!SpeechRecognition) {
      this.voiceError = 'Speech recognition is not supported in your browser.';
      console.log(this.voiceError);
      return;
    }

    this.recognition = new SpeechRecognition();
    this.recognition.lang = 'en-IN'; // English and Hindi detection
    this.recognition.interimResults = true; // Show interim results
    this.recognition.continuous = true; // Continuous listening

    this.isListening = true;
    this.voiceError = '';
    this.processedResults.clear();
    this.debounceTimeout = null;
    this.combinedTranscript = '';

    console.log('Voice recognition started.');

    this.recognition.start();

    this.recognition.onresult = (event: any) => {
      console.log('Speech recognition result:', event);

      for (let i = event.resultIndex; i < event.results.length; i++) {
        const transcript = event.results[i][0].transcript.trim();

        console.log(
          `Transcript: "${transcript}", isFinal: ${event.results[i].isFinal}`
        );

        if (event.results[i].isFinal) {
          // Process only final results to avoid duplicate and partial transcripts
          clearTimeout(this.debounceTimeout);
          this.debounceTimeout = setTimeout(() => {
            if (!this.processedResults.has(transcript)) {
              this.processedResults.add(transcript);
              this.processAudio(transcript); // Process final transcript
            } else {
              console.log(`Skipping duplicate transcript: "${transcript}"`);
            }
          }, 1000); // Adjust debounce 
        } else {
          // Log interim results without appending
          console.log('Interim transcript:', transcript);
        }
      }
    };

    this.recognition.onerror = (event: any) => {
      this.voiceError = 'Speech recognition error: ' + event.error;
      console.error(this.voiceError);
      this.stopVoiceRecognition();
    };

    this.recognition.onend = () => {
      this.isListening = false;
      console.log('Voice recognition stopped.');
    };
  }

  // Stop voice recognition
  stopVoiceRecognition() {
    if (this.recognition) {
      this.recognition.stop();
      this.isListening = false;
      console.log('Voice recognition manually stopped.');
    }
  }

  // Process audio transcript
  processAudio(transcript: string) {
    console.log('Processing transcript:', transcript);
    if (this.detectLanguage(transcript) === 'hi') {
      // Append Hindi text to the textarea first
      this.inputValue += transcript + ' ';
      console.log('Detected Hindi. Translating to English...');
      // If language is Hindi, translate it to English
      this.googleApi.translateText(transcript, 'en').subscribe({
        next: (response: any) => {
          const translatedText = response.data.translations[0].translatedText;
          this.inputValue += translatedText + ' ';
          console.log('Translation successful:', translatedText);
        },
        error: (err) => {
          console.error('Translation Error:', err);
          this.voiceError = 'Failed to translate text.';
        },
      });
    } else {
      // If language is English, directly append to text
      this.inputValue += transcript + ' ';
      console.log('Detected English. Added directly to input.');
    }
  }

  // Detect language 
  detectLanguage(text: string): string {
    const hindiRegex = /[\u0900-\u097F]/;
    const detectedLanguage = hindiRegex.test(text) ? 'hi' : 'en';
    console.log(`Language detected: ${detectedLanguage}`);
    return detectedLanguage;
  }

  // Translate text manually
  translateText() {
    if (!this.inputValue) {
      this.voiceError = 'No text to translate!';
      console.log(this.voiceError);
      return;
    }

    console.log('Manual translation started.');
    this.googleApi.translateText(this.inputValue, 'en').subscribe({
      next: (response: any) => {
        const translatedText = response.data.translations[0].translatedText;
        this.inputValue = translatedText;
        console.log('Manual translation successful:', translatedText);
      },
      error: (err) => {
        console.error('Translation Error:', err);
        this.voiceError = 'Failed to translate text.';
      },
    });
  }
}

