import { Component, EventEmitter, HostListener, Input, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
@Component({
  selector: 'app-product-details-life',
  templateUrl: './product-details-life.component.html',
  styleUrls: ['./product-details-life.component.css']
})
export class ProductDetailsLifeComponent {
  @Input() selectedProduct: any;
  @Input() pageName: String;
  @Output() customerDetails = new EventEmitter();
  @ViewChild('multiSelect') multiSelect;
  @Output() redirect = new EventEmitter();
  public settings = {};
  salesForm: FormGroup;
  selectedGender: string = 'male';
  isYesSelected: boolean = false;
  selectedLocation: string = 'top city';
  occupations = [
    { label: 'Salaried', value: 'salaried' },
    { label: 'Self-employed', value: 'self employed' },
    { label: 'Business', value: 'business' },
    { label: 'Retired', value: 'retired' },
    { label: 'Not working', value: 'not working' }
  ];
  familyMembers = [
    { item_id: 1, item_text: 'Spouse' },
    { item_id: 2, item_text: '1 child' },
    { item_id: 3, item_text: '>1 child' },
    { item_id: 4, item_text: 'Parent(s)' },
    { item_id: 5, item_text: 'None' }
  ];

  dropdownSettings = {};
  lifeGoals = [
    { item_id: 1, item_text: 'Child education' },
    { item_id: 2, item_text: 'Buy a house/car' },
    { item_id: 3, item_text: 'Save for retirement' },
    { item_id: 4, item_text: 'Financial security for the family' },
    { item_id: 5, item_text: 'Nothing specific' }
  ];
  constructor(private fb: FormBuilder) { }

  ngOnInit(): void {
    this.salesForm = this.fb.group({
      gender: [this.selectedGender],
      occupation: [''],
      age: [, [, Validators.min(0), Validators.max(120)]],
      retirementAge: [, [, Validators.min(0), Validators.max(120)]],
      familyMembers: [''],
      annualIncome: ['', [, Validators.min(0)]],
      annualExpenditure: ['', [, Validators.min(0)]],
      investments: ['', [, Validators.min(0)]],
      outstandingLoans: ['', [, Validators.min(0)]],
      lifeGoals: [''],
      amountNeeded: ['', [, Validators.min(0)]],
      healthInsurance: ['yes'],
      lifeInsuranceCoverage: [''],
      location: ['top city'],
      isSkipped: [false]
    });

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      allowSearchFilter: true
    };
  }

  decrementAge(): void {
    const age = this.salesForm.controls['age'].value;
    if (age > 0) {
      this.salesForm.controls['age'].setValue(age - 1);
    }
  }

  incrementAge(): void {
    const age = this.salesForm.controls['age'].value;
    this.salesForm.controls['age'].setValue(age + 1);
  }

  decrementRetirementAge(): void {
    const retirementAge = this.salesForm.controls['retirementAge'].value;
    if (retirementAge > 0) {
      this.salesForm.controls['retirementAge'].setValue(retirementAge - 1);
    }
  }

  incrementRetirementAge(): void {
    const retirementAge = this.salesForm.controls['retirementAge'].value;
    this.salesForm.controls['retirementAge'].setValue(retirementAge + 1);
  }

  onSubmit(isSkipped: boolean): void {
    const selectedGoals = this.selectedGoals.map(id => this.lifeGoals.find(goal => goal.item_id === id));
    this.salesForm.get('lifeGoals').setValue(selectedGoals);
    if (isSkipped)
      this.salesForm.get('isSkipped').setValue(true);
    this.customerDetails.emit(this.salesForm.value)
  }

  isDropdownOpen = false;

  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  redirectToCustomerDetails() {
    this.redirect.emit(true);
  }

  onChanges(): void {
    const lifeInsuranceControl = this.salesForm.get('lifeInsuranceCoverage');
    if (this.salesForm.get('healthInsurance').value === 'yes') {
      lifeInsuranceControl!.setValidators([Validators.required, Validators.min(0)]);
    } else {
      lifeInsuranceControl!.clearValidators();
    }

    lifeInsuranceControl!.updateValueAndValidity();
  }

  selectGender(gender: string) {
    this.selectedGender = gender;
    this.salesForm.get('gender').setValue(gender);
  }

  selectedGoals: number[] = [];

  toggleSelection(itemId: number) {
    const index = this.selectedGoals.indexOf(itemId);
    if (index > -1) {
      this.selectedGoals.splice(index, 1);
    } else {
      this.selectedGoals.push(itemId);
    }
  }

  onToggleChange() {
    this.isYesSelected = !this.isYesSelected;
    this.salesForm.get('healthInsurance').setValue(this.isYesSelected ? 'yes' : 'no');
  }

  selectLocation(location: string) {
    this.selectedLocation = location;
    this.salesForm.get('location').setValue(location);
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const target = event.target as HTMLElement;
    const clickedInside = target.closest('.navigation-options') || target.closest('.toggle-button');

    if (!clickedInside && this.isDropdownOpen) {
      this.isDropdownOpen = false;
    }
  }
}
