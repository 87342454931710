import { Component, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { VigiComponent } from './vigi.component';
import { SalesPitchComponent } from './sales-pitch/sales-pitch.component';
import { ProductSelectionComponent } from './product-selection/product-selection.component';
import { ProductQueriesComponent } from './product-queries/product-queries.component';
import { ProductRecommendationComponent } from './product-recommendation/product-recommendation.component';

const routes: Routes = [
  {
    path: 'vigi', component: VigiComponent,
    children: [
      { path: '', redirectTo: 'product-selection', pathMatch: 'full' },
      { path: 'product-selection', component: ProductSelectionComponent },
      { path: 'sales-pitch', component: SalesPitchComponent },
      { path: 'product-queries', component: ProductQueriesComponent },
      { path: 'product-recommendation', component: ProductRecommendationComponent }]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class VigiRoutingModule { }
