<div #chatContainer>
  <header>
    <div class="container mx-auto flex items-center justify-between">
      <!-- Logo and Name -->
      <div class="flex items-center">
        <button (click)="goBack()" class="focus:outline-none">
          <svg class="h-8 w-8 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 12H5M12 19l-7-7 7-7"></path>
          </svg>
        </button>
        <span class="text-xl ml-3 font-bold">Product Queries</span>
      </div>
      <!-- Kebab Icon -->
      <div class="flex items-center">
        <button class="toggle-button focus:outline-none" (click)="toggleDropdown()">
          <img class="h-6 w-6" src="../../../../assets/icons/menu.png" />
        </button>
        <div [ngClass]="{'dropdown-open': isDropdownOpen}" class="header-wrapper absolute bg-white text-black rounded-md shadow-lg overflow-hidden">
          <div class="navigation-options">
            <span routerLink="/home" class="block px-4 py-2 text-sm">Exit</span>
          </div>
        </div>
      </div>
    </div>
  </header>
  <div class="title-section h-10 flex items-center">
    <span class="mx-auto">{{selectedProduct.company+' - '+selectedProduct.product}}</span>
  </div>
  <div id="container" class="flex flex-col">
    <!-- Chat messages -->
    <div class="overflow-y-auto p-4">
      <div class="messages">
        <div *ngFor="let pair of messageResponsePairs, index as i" class="w-full mb-2 flex flex-col items-end">
          <div *ngIf="pair.message" class="w-64 mb-2 p-2 message rounded-md">
            {{ pair.message }}
          </div>
          <div *ngIf="pair.loading" class="flex items-center w-full ml-1">
            <div class="w-2 h-2 rounded-full animate-pulse loader mr-1"></div>
            <div class="w-2 h-2 rounded-full animate-pulse loader mr-1"></div>
            <div class="w-2 h-2 rounded-full animate-pulse loader"></div>
          </div>
          <div class="response-container flex flex-col items-center mb-2">
            <div *ngIf="pair.response?.length>0" class="flex flex-col items-center">
              <div [id]="i==messageResponsePairs.length-1?'final':''"
                class="flex-1 p-2 rounded-md border text-center response-box">
                <p class="text-left" [innerHTML]="pair.response[pair.currentPage]"></p>
              </div>
            </div>
          </div>
          <div class="flex justify-between w-full mt-1 mb-2 items-center">
            <!-- Like and Dislike -->
            <div *ngIf="pair.response?.length>0" class="flex justify-start">
              <div class="flex items-center ml-2">
                <div [ngClass]="{'buttonClicked': pair.isLiked}" (click)="responseLiked(pair, i)"
                  class="h-8 w-8 like rounded-full p-2">
                  <img class="h-4 w-4 cursor-pointer" src="../../../../assets/icons/like.png" />
                </div>
                <div [ngClass]="{'buttonClicked': pair.isDisliked}" (click)="this.showDialog(pair, i)"
                  class="h-8 w-8 dislike rounded-full p-2 ml-5">
                  <img class="h-4 w-4 cursor-pointer" src="../../../../assets/icons/dislike.png" />
                </div>
              </div>
            </div>
            <!-- Pagination Controls -->
            <div class="flex items-center" *ngIf="pair.totalPages > 1">
              <button (click)="previousPage(i)" [disabled]="pair.currentPage === 0" class="pagination-arrow">
                <
              </button>
              <span class="mx-2">{{ pair.currentPage + 1 }} of {{ pair.totalPages }}</span>
              <button (click)="nextPage(i)" [disabled]="pair.currentPage === pair.totalPages - 1"
                class="pagination-arrow">
                >
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Input box -->
  <div class="w-full input-full-container">
    <div class="sticky bottom-0 bg-white p-4 input-container">
      <div class="flex items-center w-full max-w-lg mx-auto">
        <textarea #messageInput [(ngModel)]="inputText" (input)="adjustTextareaHeight($event)"
          placeholder="Type your message..."
          class="flex-grow py-2 px-2 border rounded-md focus:outline-none resize-none overflow-hidden text-area"
          style="height: 40px;">
      </textarea>
        <button [disabled]="!inputText"
          class="ml-2 p-2 rounded-full text-white disabled:opacity-50 disabled:cursor-not-allowed">

          <!-- Show Send Icon if not loading -->
          <svg (click)="sendMessage(messageInput)" *ngIf="!isResponseLoading" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none"
            viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 12h14M12 5l7 7-7 7" />
          </svg>

          <!-- Show Pause Icon if loading -->
          <svg (click)="stopAPI()" *ngIf="isResponseLoading" width="100" height="100" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
            <rect x="20" y="20" width="20" height="60" fill="white" />
            <rect x="60" y="20" width="20" height="60" fill="white" />
          </svg>          
          
        </button>
      </div>
    </div>
  </div>
  <p-dialog class="dislike-dialog" [(visible)]="display" [style]="{'background-color': '#D9EAD5', 'width': '290px', 'height': '250px'}">
    <div class="flex justify-between items-start">
      <div>
        <div class="reason-buttons">
          <label class="block font-medium mb-2">What went wrong?</label>
          <div class="flex reasons-checkbox">
            <div class="mr-2">
              <div class="flex items-center mb-1">
                <input type="checkbox" id="reason1" [(ngModel)]="reason1" (change)="getSelectedReasons()">
                <label for="reason1" class="ml-1">Not relevant</label>
              </div>
              <div class="flex items-center mb-1">
                <input type="checkbox" id="reason2" [(ngModel)]="reason2" (change)="getSelectedReasons()">
                <label for="reason2" class="ml-1">Not accurate</label>
              </div>
              <div class="flex items-center mb-1">
                <input type="checkbox" id="reason3" [(ngModel)]="reason3" (change)="getSelectedReasons()">
                <label for="reason3" class="ml-1">Repetitive</label>
              </div>
            </div>
            <div>
              <div class="flex items-center mb-1">
                <input type="checkbox" id="reason4" [(ngModel)]="reason4" (change)="getSelectedReasons()">
                <label for="reason4" class="ml-1">Can't understand</label>
              </div>
              <div class="flex items-center mb-1">
                <input type="checkbox" id="others" [(ngModel)]="others" (change)="getSelectedReasons()">
                <label for="others" class="ml-1">Others</label>
              </div>
            </div>
          </div>
        </div>
        <label class="block font-medium mt-1 ml-1">Please help us understand better</label>
        <textarea class="text-xs pl-1 pt-1"  [(ngModel)]="feedback"></textarea>
      </div>
      <div class="ml-auto">
        <button class="p-link" (click)="display=false;">
          <i class="pi pi-times"></i>
        </button>
      </div>
    </div>
    <button type="button" (click)="responseDisLiked()"
      class="custom-button flex justify-center my-auto mx-auto">Submit</button>
  </p-dialog>
  <p-dialog [(visible)]="apiError" [style]="{'background-color': '#D9EAD5', 'width': '262px', 'height': '250px'}">
    API Error
  </p-dialog>
</div>